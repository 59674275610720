import React from "react";

import {Link} from "react-router-dom";

import PageComponent from "./PageComponent.jsx";
import CandidateMatrix from "./CandidateMatrix.jsx";

import Party from "../data/party.json";
import Autosuggest from 'react-autosuggest';
import {
    LineShareButton, LineIcon,
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from "react-share";
import * as axios from "axios";
import Papa from "papaparse";
import HomeFooter from "./HomeFooter.jsx";


function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class Motion extends PageComponent {


    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.animate = !this.hub.iOS && !this.hub.android; // TODO: detect frame rate instead
        this.totalPoseAnimated = 0;

        this.pageCode = 'motion';


        this.defaultDataSourceCode = 'crowdsource';
        this.realtimeUnsubscribe = null

        this.ectLabel = <span>ข้อมูลอย่างไม่เป็นทางการจาก API ของ กกต เมื่อนับคะแนนแล้ว 94%<br/>ในวันที่ 25 มีนาคม 2562</span>
        this.ect100Label = <span>ข้อมูลจาก กกต. เมื่อนับคะแนนแล้ว 100%<br/>ในวันที่ 28 มีนาคม 2562</span>

        this.state = {
            paradeMode: 'geoPartyList',
            dataSourceCode: this.defaultDataSourceCode,
            partyMPList: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
            selectedParty: {},

            // Location
            location: null,
            queryLocation: this.getLocationSuggestionValue(null),
            locationMatches: [],

            forceUpdate: false
        };

        this.urlUnlisten = null

        this.toggleMatrixMode = this.toggleMatrixMode.bind(this);
        this.renderCandidateMotion = this.renderCandidateMotion.bind(this);

        this.getDataSource = this.getDataSource.bind(this);
        this.getStatisticDataSource = this.getStatisticDataSource.bind(this);
        this.getCrowdDataSource = this.getCrowdDataSource.bind(this);
        this.getEctDataSource = this.getEctDataSource.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.onCandidateUnitPoseComplete = this.onCandidateUnitPoseComplete.bind(this);

        // Location
        this.searchLocation = this.searchLocation.bind(this);
        this.getLocationSuggestions = this.getLocationSuggestions.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onLocationSuggestionsFetchRequested = this.onLocationSuggestionsFetchRequested.bind(this);
        this.onLocationSuggestionsClearRequested = this.onLocationSuggestionsClearRequested.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationSuggestionSelected = this.onLocationSuggestionSelected.bind(this);
        this.onLocationClear = this.onLocationClear.bind(this);
        this.getLocationSuggestionValue = this.getLocationSuggestionValue.bind(this);
        this.shouldRenderLocationSuggestions = this.shouldRenderLocationSuggestions.bind(this);
        this.renderLocationItem = this.renderLocationItem.bind(this);
        this.onPartyBoxClick = this.onPartyBoxClick.bind(this);

        // Stat
        this.seeAllScore = this.seeAllScore.bind(this);
        this.shareClick = this.shareClick.bind(this);
    }

    sourceURLToCode = source => {
        return {
            'ect100': 'ect100',
            'ect94': 'ect',
            'crowdsource': 'crowdsource'
        }[source] || 'crowdsource'
    }
    componentWillMount() {
        this.urlUnlisten = this.props.history.listen((location) => {
            const source = location.pathname.split('/')[2]
            const code = this.sourceURLToCode(source)
            setTimeout(() => this.hub.logStat(this.pageCode, `motion-select-data-source-${code}`))
            this.checkActiveSource(source)
        })
    }
    componentWillUnmount() {
        if (this.urlUnlisten) this.urlUnlisten()
    }

    checkActiveSource = source => {
        // check active tab from hash
        const code = this.sourceURLToCode(source)
        this.updateDataSource(code, null, true)
    }

    componentDidMount() {
        this.checkActiveSource(this.props.match.params.source)

        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'motion-page');
    }

    getDataSourcePath = (source) => {
        const appMode = this.hub.appMode

        let path = {
            'ect': `${appMode}--cache/ect-elect-results`,
            'crowdsource': `${appMode}--cache/elect-results--people`,
            'statistic': `production--cache/aggregate--candidate-detail-page`
        }

        return path[source]
    }

    async getDataSource(code) {
        code = code || this.state.dataSourceCode

        if (this.realtimeUnsubscribe) {
            this.realtimeUnsubscribe()
        }

        let resultsPromise
        if (code === 'statistic') {
            resultsPromise = this.getStatisticDataSource()
        } else if (code === 'crowdsource') {
            resultsPromise = this.getCrowdDataSource()
        } else if (code === 'ect') {
            resultsPromise = this.getEctDataSource()
        } else if (code === 'ect100') {
            resultsPromise = this.getEct100DataSource()
        } else {
            resultsPromise = []
        }

        let results = await resultsPromise
        const endpoint = this.getDataSourcePath(code)

        if (endpoint) {
            this.realtimeUnsubscribe = this.hub.db.doc(endpoint).onSnapshot(snapshot => {
                console.log('-> receive realtime data', code)
                if (snapshot.data()) {
                    this.updateDataSource(code, JSON.parse(snapshot.data().data || '{}'))
                }
            }, e => {
                console.log('-> realtime stream error', e)
            })
        }

        return results
    }

    getStatisticDataSource(random) {

        const rearrange = (item, key, ignore) => {

            key = key || 'party';

            let adjustTotal = 0;
            item[key].forEach(party => {

                // let newTotal = (party.total + 1) * 30;
                let newTotal = party.total;
                if (random) {
                    newTotal += Math.floor(Math.random()*100);
                }

                if (party.key === 'พรรคอนาคตใหม่') {
                    newTotal = Math.floor(newTotal/20);
                }
                else if (party.key === 'พรรคเศรษฐกิจใหม่') {
                    newTotal = Math.floor(newTotal/7);
                } else if (party.key === 'พรรคเสรีรวมไทย') {
                    newTotal = Math.floor(newTotal/4);
                } else if (party.key === 'พรรคพลังประชารัฐ') {
                    newTotal = Math.floor(newTotal*1.8);
                }
                
                if (this.hub.terminates.indexOf(party.key) !== -1) {
                    newTotal = 0;
                }

                adjustTotal += newTotal - party.total;
                party.total = newTotal;

            });

            if (!ignore) {
                item.total += adjustTotal;
            }

            return adjustTotal;

        };

        return this.hub.db.collection(`production--cache`).doc(`aggregate--candidate-detail-page`).get().then(doc => {
            // return {};
            let data = JSON.parse(doc.data().data || '{}');
            data.province.forEach(p => {
                p.zones.forEach(z => {
                    rearrange(z);
                    z.total = z.party.reduce((a, b) => a + b.total, 0);
                });
                rearrange(p);
                p.total = p.zones.reduce((a, b) => a + b.total, 0);
            });
            data.total = data.province.reduce((a, b) => a + b.total, 0);
            rearrange(data, 'popular', true);

            return data;
        })
    };

    async getCrowdDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getEctDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/ect-elect-results`).get()
            if (ref.exists) {

                let dataSource = JSON.parse(ref.data().data);
                dataSource.noVoteTotal = dataSource.noVoteTotal || 0
                dataSource.voidedTotal = dataSource.voidedTotal || 0
                return dataSource
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getEct100DataSource() {
        let url = 'https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fect100.csv?alt=media';
        let resp = await axios.get(url);
        let data = Papa.parse(resp.data, {header: true}).data;

        // console.log(data);

        let candidateMap = {};
        this.hub.candidates.forEach(c => {
            if (!candidateMap[c.province]) candidateMap[c.province] = {};
            if (!candidateMap[c.province][c.zone]) candidateMap[c.province][c.zone] = {};
            if (!candidateMap[c.province][c.zone][c.no]) candidateMap[c.province][c.zone][c.no] = c;
        });

        // console.log(this.hub.candidates)
        // console.log('data', data)
        let result = {province: [], popular: [], total: 0};
        let provinceMap = {}, provinceList = [];
        let popularMap = {}, popularList = [];

        let csv = '';

        data.forEach(row => {
            let province = row.province; // TODO: clean province
            // get candidate from this.hub.candidates map with row data
            let candidate = candidateMap[row.province][row.zone][row.no];
            province = candidate.province;

            let zone = candidate.zone;
            let party = candidate.party;

            if (!provinceMap[candidate.province]) provinceMap[province] = {key: province, total: 0, party: [], zones: {}};
            if (!provinceMap[candidate.province].zones[zone]) provinceMap[province].zones[zone] = {key: zone, total: 0, party: []};
            if (!popularMap[party]) popularMap[party] = {key: party, total: 0};

            let score = parseInt(row.score);

            result.total += score;

            provinceMap[province].zones[zone].total += score;
            provinceMap[province].zones[zone].party.push({
                key: candidate.party,
                total: score
            });

            popularMap[party].total += score;
        });


        Object.keys(provinceMap).map(function(provinceKey) {
            let province = provinceMap[provinceKey];
            let zoneList = [];
            Object.keys(province.zones).map(function(zoneKey) {
                let zone = provinceMap[provinceKey].zones[zoneKey];
                zoneList.push(zone);
            });
            province.zones = zoneList;
            provinceList.push(province);
        });
        result.province = provinceList;

        Object.keys(popularMap).map(function(popularKey) {
            let popular = popularMap[popularKey];
            popularList.push(popular);
        });
        result.popular = popularList;

        result.noVoteTotal = 605392;
        result.voidedTotal = 2130327;

        return result;


    }

    // updatePartyList(dataSource) {
    //     dataSource.province.forEach(p)
    // }

    async updateDataSource(code, preloadedDataSource, forceUpdate) {

        if (!this.hub.setting.isInputOpen) {
            this.setState({dataSource: {
                goodVoteTotal: 0,
                noVoteTotal: 0,
                partyShouldMP: [],
                province: {},
                succeedTotal: 0,
                total: 0,
                voidedTotal: 0,
            }})
            return
        }

        let dataSource;
        let cache = JSON.parse(localStorage.getItem(`cache-dataSource-${code || this.defaultDataSourceCode}`));
        let hit = cache && (Date.now() - parseInt(cache.timestamp)) < 1*60*1000;

        if (hit && !preloadedDataSource) { // TODO: get data source from cache every 1 minute (success) or when receive realtime message from firebase

            dataSource = cache.value;

        } else { // build data source

            let forceUpdate = Boolean(preloadedDataSource) || forceUpdate;

            this.setState({
                preparing: true,
                forceUpdate: forceUpdate
            });
            if (!preloadedDataSource) {
                dataSource = await this.getDataSource(code)
            } else {
                dataSource = preloadedDataSource
            }

            this.hub.utils.processDataSource(dataSource)

        }


        // serve and set state from cache or build data

        let partyMPList = [].concat(dataSource.partyShouldMP);
        partyMPList.sort((a,b) => (a.allTotal > b.allTotal) ? -1 : ((b.allTotal > a.allTotal) ? 1 : 0));

        let newState = {
            dataSource: dataSource,
            partyMPList: partyMPList,
            selectedParty: {},
            preparing: false,
            forceUpdate: false
        };
        if (code) {
            newState.dataSourceCode = code;
        }
        this.setState(newState);

        if (!hit || preloadedDataSource) {
            cache = JSON.stringify({value: dataSource, timestamp: Date.now()});
            localStorage.setItem(`cache-dataSource-${code || this.defaultDataSourceCode}`, cache);
        }
    }


    setParadeMode(mode) {
        this.setState({
            loading: true
        });

        const self = this;
        setTimeout(() => {
            let newState = {
                paradeMode: mode
            };
            if (!this.animate) {
                newState.loading = false;
            }
            self.setState(newState);
            
        }, 1000);

    }

    toggleMatrixMode() {
        if (this.state.loading) {
            return;
        }
        const newMode = this.state.paradeMode === 'geoPartyList'? 'council': 'geoPartyList';
        this.setParadeMode(newMode);
        this.hub.logStat(this.pageCode, 'motion-set-parade-mode-'+newMode);
    }

    onCandidateUnitPoseComplete() {

        if (this.animate) {
            this.totalPoseAnimated++;
            if (this.totalPoseAnimated >= 500) {
                this.setState({loading: false});
                this.totalPoseAnimated = 0;
            }
        }
    }

    // ===================================================
    // Location
    // ===================================================

    searchLocation(keywords, items, matches, limit, parents, root) {

        var self = this;

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = item.postcode ? item.name + ':' + item.postcode: item.name;
            label = parents ? parents + '|' + label : label;

            let province = root? root: item.name;

            let isMatch = !keywords || keywords.filter(keyword => label.indexOf(keyword) !== -1).length >= keywords.length;

            if (false && item.children) {
                self.searchLocation(keywords, item.children, matches, limit, label, province);
            } else if (isMatch) {
                matches.push({
                    label: label,
                    id: item.id,
                    province: province,
                });
            }

        });
    }

    getLocationSuggestions(value) {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];
        if (value.length >= 1) {
            this.searchLocation(keywords, this.hub.zones, matches, 10);
        }
        return matches;
    }


    onLocationChange(event, { newValue }) {
        this.setState({
            location: null,
            queryLocation: newValue
        });
    }

    onLocationSuggestionsFetchRequested({ value }) {
        this.setState({
            location: null,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    onLocationSuggestionsClearRequested() {
        this.setState({
            locationMatches: []
        });
    }

    onLocationSelected(location) {
        if (location) {
            this.setState({location: location});
            $(this.refs.candidateDistrictModal).modal('show');
            this.hub.logStat(this.pageCode, 'motion-on-location-select', location);
        }
    }

    onLocationSuggestionSelected(event, { suggestion }) {
        this.onLocationSelected(suggestion);
    }

    onLocationClear(event) {
        this.refs.locationAutosuggest.props.inputProps.value = '';
        this.refs.locationAutosuggest.input.value = '';
        this.refs.locationAutosuggest.input.focus();

        let value = '';
        this.setState({
            location: null,
            queryLocation: value,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    getLocationSuggestionValue(item) {
        if (!item) return '';
        const location = item.label.split('|').map(level => level.split(':')[0]);
        return location.join(' » ');
    }

    shouldRenderLocationSuggestions(value) {
        return true;
    }

    renderLocationItem(item) {
        return this.getLocationSuggestionValue(item);
    }



    renderCandidateMotion() {

        let ds = this.state.dataSource;
        return (
            <div className={`candidate-motion-container ${this.state.paradeMode}`}>
                <div className={`candidate-motion-wrapper px-0`} style={{marginLeft: '18%', marginRight: '18%'}}>
                    <CandidateMatrix
                        hub={this.hub}
                        forceUpdate={this.state.forceUpdate}
                        paradeMode={this.state.paradeMode}
                        dataSource={ds}
                        dataSourceCode={this.state.dataSourceCode}
                        Party={Party} animate={this.animate}
                        onCandidateUnitPoseComplete={this.onCandidateUnitPoseComplete}
                        onCandidateUnitClick={this.onLocationSelected}
                        selectedParty={this.state.selectedParty}
                    />
                </div>
            </div>
        )
    }

    onPartyBoxClick(party) {
        let selectedParty = JSON.parse(JSON.stringify(this.state.selectedParty));
        selectedParty[party.key] = !selectedParty[party.key];
        if (!selectedParty[party.key]) {
            delete(selectedParty[party.key]);
        }
        this.setState({selectedParty: selectedParty});
    }

    partyBoxItem(party, i) {

        if (!party) {
            party = {
                shortName: '...',
                color: null,
                allTotal: '...',
                zoneTotal: '...',
                finalMoreTotal: '...'
            }
        }
        return (
            <div key={`party-box-item-${i}`}>
                <div onClick={() => {this.onPartyBoxClick(party)}}
                    className={`party-box-item party d-flex flex-column justify-content-between align-items-strech text-center ml-2 ${this.state.selectedParty[party.key]? 'active': ''}`}>
                    <div className="party-name d-flex justify-content-center flex-column" style={{backgroundColor: party.color}}>
                        <div className="text-white _fw-bd">{party.shortName || party.key.replace('พรรค', '')}</div>
                    </div>
                    <div className="quota-seat d-flex justify-content-center flex-column">
                        <div className="mb-0">{party.allTotal}</div>
                    </div>
                    <div className="quota-type d-flex flex-row justify-content-center align-items-center bg-black text-white">
                        <div className="area">{party.zoneTotal}</div>
                        <span className="mx-1">|</span>
                        <div className="party-list ">{party.finalMoreTotal}</div>
                    </div>
                </div>
            </div>
        )
    }

    partyModalBoxItem(party, i, max) {

        let percent = 0;

        if (party) {
            percent = Math.round(party.allTotal/max*100)
        } else {
            party = {
                key: '...',
                shortName: '...',
                color: null,
                allTotal: '...',
                zoneTotal: '...',
                finalMoreTotal: '...',
                score: '...'
            }
        }
        return (
            <div key={`party-modal-box-item-${i}`} className="list-group-item party-modal-box-item -party d-flex flex-row justify-content-between align-items-center w-100 rounded-0 border-0 px-3">
                <div className="left w-60 pr-3">
                    <div className="party-name d-flex justify-content-center flex-column _fw-bd _fs-24">
                        <div className="text-white">{party.key.replace('พรรค', '')}</div>
                    </div>
                    <div className="bar mb-1">
                        <div className="progress bg-trans rounded-0">
                            <div className="progress-bar" role="progressbar" style={{width: percent + '%', backgroundColor: party.color}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" />
                        </div>
                    </div>
                    <div className="_fw-li _fs-16">{numberWithCommas(party.score)} คะแนน</div>
                </div>
                <div className="right d-flex flex-column text-right">
                    <div className="quota-seat d-flex justify-content-center flex-column">
                        <div className="mb-0 _fw-med _fs-42 _fs-30-sm _lh-80">{party.allTotal}</div>
                    </div>
                    <div className="vote-count text-white">
                        <div className="_fw-li _fs-20">{numberWithCommas(party.zoneTotal)} + {numberWithCommas(party.finalMoreTotal)}</div>
                    </div>
                </div>
            </div>
        )
    }

    candidateTableModal() {
        let max = this.state.partyMPList.reduce((a, b) => b && b.allTotal > a ? b.allTotal: a , 0);

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>* ข้อมูลจากประชาชน (crowdsourcing)<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        return (
            <div className="candidate-table modal fade" id="ranking-modal" tabIndex="-1" role="dialog" aria-labelledby="rankingModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-full" role="document">
                    <div className="modal-content light-grey-bg text-white border-0 rounded-0 h-100">
                        <div className="container d-flex flex-column h-100">
                            <div className="modal-header d-block border-bottom-0">
                                <div className="d-flex flex-column align-items-start pb-2">
                                    <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                        <button type="button" className="close text-white _opa-100 ml-0 mr-auto pl-0 mb-0" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" className="_fw-reg _fs-20"><img src="/static/images/ic-close.png" srcSet="/static/images/ic-close.png 1x, /static/images/ic-close@2x.png 2x" className="mr-2" /> ปิด</span>
                                        </button>
                                        <div className="remark text-right _fs-18 _fw-reg">
                                            {sourceLabel}
                                        </div>
                                    </div>
                                    <div className="party-modal-box-item -party d-flex flex-row justify-content-between align-items-center w-100 _fw-bd bg-trans">
                                        <div className="left w-100 pr-3 _fs-16 _fw-med">พรรค</div>
                                        <div className="right d-flex flex-column text-right _fs-16 _fw-med">
                                            <div className="">ที่นั่ง</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body overflow-y-scroll pt-0">
                                <div className="list-group row">
                                    {this.state.partyMPList.map((party, i) => this.partyModalBoxItem(party, i, max))}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }    

    districtModalBoxItem(party, i, max) {
        let percent = 0;
        if (party) {
            percent = Math.round(party.total/max*100)
        }

        return (
            <div key={`party-modal-box-item-${i}`} className="list-group-item party-modal-box-item -district d-flex flex-column justify-content-between w-100 mb-0 rounded-0 border-0 px-3">
                <div className="w-100">
                    <div className="d-flex align-items-center flex-row justify-content-start mb-2">
                        {party.candidate ?
                            <div className="text-white text-center border border-color-white bg-transparent candidate-number _fs-30 _fw-med mr-2">
                                {party.key.split('|')[0]}
                            </div>
                            : ''
                        }
                        <div className="_fw-med">
                            <div className="party-name d-flex justify-content-center flex-column _fs-20">
                                <div className="text-white">{party.candidateName}</div>
                            </div>
                            {/* <div className="party-name d-flex justify-content-center flex-column _fs-16">
                                {party.candidate ? <div className="text-white">{party.key.split('|')[2]}</div> : ''}
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="below d-flex flex-row align-items-end justify-content-between">
                    <div className="bar w-60 pb-1">
                        <div className="progress bg-trans rounded-0">
                            <div className="progress-bar" role="progressbar" style={{width: percent + '%', backgroundColor: party.color}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" />
                        </div>
                    </div>
                    <div className="w-40 d-flex flex-column text-right">
                        <div className="score">
                            <div className="mb-0 _fs-32 _fw-med">{numberWithCommas(party.total)}</div>
                        </div>
                        <div className="vote-count text-white">
                            <div className="_fs-16 _fw-med">คะแนน</div>
                        </div>
                    </div>
                </div>
            </div>            
        )
    }

    seeAllScore() {
        this.hub.logStat(this.pageCode, 'motion-see-all-score');
    }

    shareClick() {
        this.hub.logStat(this.pageCode, 'motion-share');
    }

    close() {
        this.setState({ showModal: false });
    }

    candidateDistrictModal() {

        let candidates = [];
        let location = this.state.location;
        let max = 0;
        const votePvt = location? this.hub.votePvt[location.province]: null


        let province;
        if (location && this.state.dataSource) {
            if (this.state.dataSource.province[location.province]) {
                province = this.state.dataSource.province[location.province];

                let basecCandidates = this.hub.candidates.filter(i => i.province === location.province);

                basecCandidates = basecCandidates.reduce((obj, candidate) => {obj[candidate.party] = candidate; return obj;}, {});


                province.party.forEach(p => {

                    let partyName = p.key.split('|')[2].replace('พรรค', '');
                    p.color = (Party[partyName] && Party[partyName].color) || '#888888';
                    p.shortName = (Party[partyName] && Party[partyName].shortName) || '';
                    p.candidateName = p.key.split('|')[1];

                    let c = basecCandidates['พรรค' + partyName];
                    if (c) {
                        p.candidate = c;
                        // p.candidateName = `${c.prefix}${c.firstname} ${c.lastname}`;
                    }

                });
                candidates = province.party;
                max = candidates.reduce((a, b) => b && b.total > a ? b.total: a , 0);
            }
        }

        if (!location) {
            location = {province: '...'};
        }

        const candidatesOnly = candidates.filter(it => ['voided', 'noVote'].indexOf(it.key.split('|')[2]) === -1)
        const noVote = candidates.find(it => it.key.split('|')[2] === 'noVote') || {total: 0}
        const voided = candidates.find(it => it.key.split('|')[2] === 'voided') || {total: 0}

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>*จากการรายงานภาคประชาชน<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        console.log(this.hub.unitResults)
        let totalUtits = this.hub.unitResults[location.province] ? this.hub.unitResults[location.province].total :0
        

        return (
            <div ref="candidateDistrictModal" className="candidate-district-modal modal fade pr-0" id="candidateDistrictModal" tabIndex="-1" role="dialog" aria-labelledby="candidateDistrictModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-full modal-dialog-centered" role="document">
                    <div className="modal-content light-grey-bg _op-30 text-white border-0 rounded-0 h-100">
                        <div className="container d-flex flex-column h-100">
                            <div className="modal-header d-block border-bottom-0 pb-3 bg-black">
                                <div className="d-flex flex-column align-items-start">
                                    <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                        <button type="button" className="close text-white _opa-100 ml-0 mr-auto pr-0 mb-0" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" className="_fw-reg _fs-20"><img src="/static/images/ic-close.png" srcSet="/static/images/ic-close.png 1x, /static/images/ic-close@2x.png 2x" className="mr-2" /> ปิด</span>
                                        </button>
                                        <div className="remark text-right _fs-18 _fw-reg">
                                            {this.state.dataSourceCode === 'crowdsource' ?
                                            <>
                                                <div className="crowdsourcing-zone-detail-link mt-3">
                                                    <Link to={`/crowdsource/result/${location.province}`} onClick={() => {$(this.refs.candidateDistrictModal).modal('hide');}} className={`btn rounded _fw-reg text-white py-1 d-block`}>
                                                        <div className="_fs-20 d-inline-block mr-1">หน่วยที่ยังไม่มีรูป</div>
                                                        <img src="/static/images/ic-arrow-right.png"
                                                                srcSet="/static/images/ic-arrow-right.png 1x, /static/images/ic-arrow-right@2x.png 2x"
                                                                width="10px"
                                                                className="d-inline-block" />
                                                    </Link>
                                                    <Link to={`/crowdsource/search/${location.province}`} onClick={() => {$(this.refs.candidateDistrictModal).modal('hide');}} className={`btn rounded _fw-reg text-white py-1 d-block mt-2`}>
                                                        <div className="_fs-20 d-inline-block mr-1">รูปกระดานคะแนน</div>
                                                        <img src="/static/images/ic-arrow-right.png"
                                                                srcSet="/static/images/ic-arrow-right.png 1x, /static/images/ic-arrow-right@2x.png 2x"
                                                                width="10px"
                                                                className="d-inline-block" />
                                                    </Link>
                                                </div>
                                            </>
                                            : ''}
                                        </div>

                                    </div>
                                    <div className={`row mt-2`} style={{width: 'calc(100% + 30px)'}}>
                                        <div className="col text-left">
                                            <div className="_fw-med _fs-18">คะแนนรวม</div>
                                            <div className="_fw-med _fs-32">จังหวัด {location.province}</div>
                                        </div>
                                        {(this.state.dataSourceCode === 'crowdsource' && votePvt) ?
                                        <div className="col text-right">
                                            <div className="_fw-med _fs-18">หน่วยที่รายงานเข้ามา</div>
                                            {/* <div className="_fw-med _fs-32">{Math.min(100, Math.floor(3/votePvt['sample size']*100))}%</div> */}
                                            <div className="_fw-med _fs-32">{Math.min(100, (totalUtits/parseInt(votePvt['total station'].replace(/,/g, ''))*100).toFixed(2))}%</div>
                                            <div className={`_fw-med _fs-14`}>{sourceLabel}</div>

                                        </div>
                                        : ``}
                                    </div>
                                </div>


                            </div>

                            {(this.state.dataSourceCode === 'crowdsource' && votePvt && totalUtits < parseInt(votePvt['sample size'].replace(/,/g, ''))) ?
                            <div className={`bg-efefef text-center _fw-med _fs-22 text-dark pb-1 pt-2`} style={{marginLeft: -15, marginRight: -15}}>
                                ต้องการข้อมูลคะแนนทั้งหมด {votePvt['sample size']} หน่วย เพื่อคาดเดาผู้ชนะ <br />
                                ขณะนี้มีข้อมูลแล้ว <span className="_fs-32">{totalUtits}</span>/{votePvt['sample size']} หน่วย
                            </div>
                            : ``}
                            <div className="modal-body overflow-y-scroll px-0 pt-3" style={{flexGrow: '1'}}>
                                <div className="list-group">
                                    {candidatesOnly.length > 0 && candidatesOnly.filter(p => p.total > 0).length >= 1?
                                        candidatesOnly.map((party, i) => {
                                            return this.districtModalBoxItem(party, i, max)
                                        }): <h3>! ขณะนี้ยังไม่มีการส่งรายงานเข้ามา</h3>
                                    }
                                </div>



                            </div>
                            {(this.state.dataSourceCode === 'crowdsource' && votePvt) ?
                            <div className={`bg-black modal-footer row p-0`}>
                                <div className={`virtual-unit-result col`} style={{columnCount: 1, columnGap: 0}}>
                                    <div className="monitoring-info text-white p-2">
                                        <ul className="list-unstyled m-0 p-0">
                                            <li className="_fs-18 _fw-reg">
                                                <div className="monitoring-status d-inline-block mr-2 has-result">
                                                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ border: "1px solid white" }}>
                                                        <img className="img-fluid d-block d-none" src="/static/images/ic-check-white.png" srcSet="/static/images/ic-check-white.png 1x, /static/images/ic-check-white@2x.png 2x" width="8px" />
                                                    </div>
                                                </div>
                                                <div>กรอกคะแนนแล้ว</div>
                                                <div className="count"><span className="_fs-32 _fw-bd">{totalUtits}</span> / <span>{votePvt['total station']}</span> <span>หน่วย</span></div>
                                            </li>
                                            <li className="_fs-18 _fw-reg">
                                                <div className="monitoring-status d-inline-block mr-2 empty">
                                                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ backgroundColor: "transparent" }}>
                                                        <div className="rounded-circle">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div>หน่วยที่ยังไม่มีรูปจากหน้าหน่วย</div>
                                                <div className="count"><span className="_fs-32 _fw-bd" style={{ color: '#A34B67' }}>{parseInt(votePvt['total station'].replace(/,/g, '')) - totalUtits}</span> / <span>{votePvt['total station']}</span> <span>หน่วย</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            : ``}


                            {/* {zone?
                                <div className="px-2 pb-3 mt-3">
                                    <div className="row">
                                        <div className="col-5 px-1 d-none d-sm-block">
                                            <div className="_fs-18 _fw-med">จำนวนผู้มาใช้สิทธิ์เลือกตั้ง</div>
                                            <div className="_fs-30 _fw-bd">{numberWithCommas(zone.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">บัตรดี</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(zone.total - voided.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">บัตรเสีย</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(voided.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">Vote NO</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(noVote.total)}</div>
                                        </div>
                                    </div>
                                </div>: ''
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoSource = source => {
        this.props.history.push(`/visual/${source}`)
        this.checkActiveSource(source)
    }

    render() {

        let ds = this.state.dataSource;
        let electorTotal = this.state.dataSourceCode === 'ect' ? 50986149: 50986149; // change this


        const {
            queryLocation, locationMatches
        } = this.state;

        const locationInputProps = {
            id: 'input-location',
            placeholder: 'ค้นหาจากจังหวัด',
            className: 'form-control _fw-med',
            value: queryLocation,
            onChange: this.onLocationChange
        };

        let locationButton;
        if (queryLocation) {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" onClick={this.onLocationClear} type="button"><i className="fa fa-close"> </i></button>
                </div>
            );
        } else {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"><img src="/static/images/ic-search.png" srcSet="/static/images/ic-search.png 1x, /static/images/ic-search@2x.png 2x" width="18px"/></button>
                </div>
            );
        }


        let totalCome = 0
        if (ds) {
            totalCome = (((ds.goodVoteTotal + ds.noVoteTotal + ds.voidedTotal) * 100)/electorTotal).toFixed(2)
        }

        // ===================================================
        // ButtonText
        // ===================================================

        let buttonText;
        if (this.state.paradeMode === 'geoPartyList') {
            buttonText = (
                <div className="btn-motion py-1 -council d-flex flex-row justify-content-end">
                    <div className="_fw-med text-right">
                        <div className="_fs-22 _lh-80">ที่นั่ง</div>
                        <div className="_fs-14">ในสภา</div>
                    </div>
                </div>
            );
        } else {
            buttonText = (
                <div className="btn-motion py-1 -geo d-flex flex-row justify-content-end">
                    <div className="_fw-med text-right">
                        <div className="_fs-22 _lh-80">สส.​</div>
                        <div className="_fs-14">แบบแบ่งเขต</div>
                    </div>
                </div>
            );
        }
        if (this.state.loading) {
            buttonText = (
                <div className="btn-motion px-0 bg-white">
                    <img src="/static/images/preloading.gif" alt="loading ..." width="auto" height="20px" />
                    <div className="_lh-80"><small>กำลังประมวลผล</small></div>
                </div>
            );
        }

        const setCSModal = ref => {
            // jQuery(ref).modal()
        }

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>* ข้อมูลจากประชาชน crowdsourcing<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        return (
            <div className="">
                <div className="px-0 container candidate-motion-header position-relative mb-3">

                    <div className={`text-center mt-3 mb-3`}>
                        <h3 className="mr-1 mr-sm-3"><strong>ผลการรายงานคะแนนเลือกตั้ง</strong><br /><strong>นายกองค์การบริหารส่วนจังหวัด (อบจ.)</strong></h3>

                        <div className="container condidate-motion-wrapper p-2 px-sm-3 pt-sm-3 pb-sm-2">
                            <div className="d-flex flex-row align-items-center justify-content-center">

                                <div className="candidate-motion-source d-flex flex-row justify-content-start justify-content-sm-center align-items-center _fs-18 _fw-bd">
                                    <div className="mr-1 mr-sm-3">ผลการนับคะแนนจาก</div>
                                    {/* <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect100'); this.gotoSource('')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect100'? ' btn-secondary active': ''}`}>กกต. 100%</button>
                                    <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect'); this.gotoSource('ect94')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect'? ' btn-secondary active': ''}`}>กกต. 94%</button> */}
                                    <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-crowdsource'); this.gotoSource('crowdsource')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'crowdsource'? ' btn-secondary active': ''}`}>ประชาชน</button>
                                    {/* <button type="button" onClick={() => {this.updateDataSource('statistic')}} className={`d-none px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'statistic'? ' btn-secondary active': ''}`}>สถิติ</button> */}
                                </div>
                                </div>
                        </div>
                    </div>

                    <div className="search-switch-wrapper" style={{maxWidth: 'none'}}>
                        <div className="search-box-wrapper text-center mx-auto">
                            <div className="input-group">
                                <Autosuggest
                                    ref="locationAutosuggest"
                                    suggestions={locationMatches}
                                    onSuggestionsFetchRequested={this.onLocationSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onLocationSuggestionsClearRequested}
                                    onSuggestionSelected={this.onLocationSuggestionSelected}
                                    getSuggestionValue={this.getLocationSuggestionValue}
                                    shouldRenderSuggestions={this.shouldRenderLocationSuggestions}
                                    renderSuggestion={this.renderLocationItem}
                                    inputProps={locationInputProps}
                                />
                                {locationButton}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container mb-5">
                    <div className="candidate-motion-middle">
                        <div ref="mainContent" className={`candidate-motion-main-content${this.state.dataSourceCode === 'ect'? ' etc': ''}`}>
                            {this.renderCandidateMotion()}
                        </div>
                        <div className="remark text-center _fs-18 _fw-reg mt-4">
                            {sourceLabel}
                        </div>
                    </div>
                </div>

                

                {this.candidateTableModal()}
                
                {this.candidateDistrictModal()}

                {this.state.preparing && !this.state.forceUpdate?
                <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                    <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                        <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                            <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px', marginBottom: '1rem'}}/>
                            <h4 className="_fw-bd _fs-20">กำลังประมวลผลรายงาน</h4>
                        </div>
                    </div>
                </div>: ''
                }

                <div className={`bg-black _fw-bd _fs-24 text-center p-2 `}>
                    <Link className={`text-white`} to={`/candidates`}><u>ดูรายชื่อผู้สมัคร</u></Link>
                </div>
                <HomeFooter hub={this.hub} />


            </div>
        )
    }
}
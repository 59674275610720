import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import ReactGA from 'react-ga';

import React, {Component} from "react";
import ReactDOM from "@hot-loader/react-dom";
// import { AppContainer } from 'react-hot-loader'

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Papa from "papaparse";

import Header from "./Header.jsx";
import SearchForm from "./SearchForm.jsx";
import Overview from "./Overview.jsx";
import Motion from "./Motion.jsx";
import LocationDetail from "./LocationDetail.jsx";
import CandidateDetail from "./CandidateDetail.jsx";
import CandidateImage from "./CandidateImage.jsx";
import CandidatePrint from "./CandidatePrint.jsx";
import Visual from "./Visual.jsx";
import News from "./News.jsx";
import NoMatch from "./NoMatch.jsx";
import Utils from "../../Utils.jsx";
import CrowdSourcingVoteSubmit from './CrowdSourcingVoteSubmit.jsx'
import CrowdSourcingThankYou from './CrowdSourcingThankYou.jsx'
import CrowdSourcing from './CrowdSourcing.jsx'
import CrowdSourcingRandom from './CrowdSourcingRandom.jsx'
import CrowdSourcingOverview from './CrowdSourcingOverview.jsx'
import AdminCrowdSourcing from './admin/AdminCrowdSourcing.jsx'
// import CrowdSourcingSearch from "./CrowdSourcingSearch.jsx";
// import CrowdSourcingProvinceDetail from "./CrowdSourcingProvinceDetail.jsx";
import CrowdSourcingZoneDetail from "./CrowdSourcingZoneDetail.jsx";
import CrowdSourcingZoneResult from "./CrowdSourcingZoneResult.jsx";

import * as axios from 'axios'
import Redirect from 'react-router-dom/es/Redirect'
import VisualSearchProgress from './VisualSearchProgress.jsx';
import VisualTable from './VisualTable.jsx';

// hot reload
// import { hot } from 'react-hot-loader/root'

// Prevent log in production
let appMode = 'development';
let isDevSite = (window.location.hostname.indexOf('dev.') === 0)

if (typeof(PRODUCTION) !== "undefined" && !isDevSite) {
    appMode = 'production';
}
appMode = 'production';


// if (appMode !== 'development') {
//     // inject raven
//     let _log = window.console.log
//     // eslint-disable-next-line no-global-assign
//     window.console.log = (...args) => {
//         _log.call(window.console, ...args)

//         args.forEach(item => {
//             if (item instanceof Error) {
//                 if (window.Raven) {
//                     Raven.captureException(item)
//                 }
//             }
//         })
//     }

//     Raven
//         .config('https://8e408f0d66d747e9943585ec03b612a2@sentry.opendream.co.th/8', {
//             logger: 'javascript',
//             environment: appMode
//         })
//         .install()
// }

// Safe old browser
String.prototype.endsWith = function(suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class App extends Component {

    constructor(props) {
        super(props);

        this.appWrapper = null

        // Initialize Firebase
        const config = {
            apiKey: "AIzaSyANKjG7qgXQnwIEip9tfbLaLcps69ZKnYg",
            authDomain: "thai-vote-pao-62.firebaseapp.com",
            databaseURL: "https://thai-vote-pao-62.firebaseio.com",
            projectId: "thai-vote-pao-62",
            storageBucket: "thai-vote-pao-62.appspot.com",
            messagingSenderId: "720215431952",
            useEmulator: appMode === 'development' && !isDevSite,
            emulatorFunctionsUrl: 'http://localhost:5000'
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }

        let db = firebase.firestore();
        let functions = firebase.functions()

        let referrerUrl = decodeURIComponent(document.referrer || '');
        let referrerDomain = '';
        if (referrerUrl) {
            referrerDomain = referrerUrl.split('://')[1].split('/')[0].split(':')[0];
            if (window.location.hostname === referrerDomain) {
                referrerUrl = '';
                referrerDomain = '';
            }
        }

        if (appMode === 'development' && !isDevSite) {
            functions.useFunctionsEmulator('http://localhost:5001')
        }

        // Change this date
        const startInputDateTime = new Date(2020, 12-1, 20, 17, 0)
        const visualDateTime = new Date(2020, 12-1, 20, 8, 0)

        const isInputOpen = (new Date() >= startInputDateTime) || (appMode === 'development') || (window.location.search.indexOf('open') !== -1)
        const isHomeVisual = (new Date() >= visualDateTime)
        const setting = {
            startInputDateTime: startInputDateTime,
            isInputOpen: isInputOpen,
            isHomeVisual: isHomeVisual,
        }

        this.hub = {
            config: config,
            db: db,
            functions: functions,
            appMode: appMode,
            setting: setting
        };

        // Paths for analytic
        let paths = [];
        let totalPaths = 0;
        this.hub.pushPath = (pageCode) => {
            const lastestPath = paths[paths.length - 1];
            if (typeof (lastestPath) == 'undefined' || pageCode !== lastestPath) {
                paths.push(pageCode);
                totalPaths++;
                paths = paths.slice(paths.length - 10, paths.length);
            }
        };
        // Initialize Data
        let userId = window.localStorage.getItem('userId');
        if (!userId) {
            userId = uuidv4();
            try {
                window.localStorage.setItem('userId', userId);
            } catch (e) {
                // Safe old ios device in safari private mode
                window.localStorage.setItem = (key, value) => null;
            }
        }

        this.hub.userId = userId;
        this.hub.ageRange = window.localStorage.getItem('ageRange') || '';

        // Terminate parties
        this.hub.terminates = ['พรรคตัดสิทธิ์'];
        // Analytic

        const ua = navigator.userAgent.toLowerCase()
        this.hub.android = ua.indexOf('android') > -1;
        this.hub.iOS = /ipad|iphone|ipod/.test(ua) && !window.MSStream;

        let device = 'desktop';
        if (this.hub.iOS) {
            device = 'ios';
        } else if (this.hub.android) {
            device = 'android';
        }

        ReactGA.initialize('UA-135694244-1');
        this.hub.logStat = (source, key, value, callback) => {

            key = appMode + '--' + key;

            let data = {
                meta: {
                    source: source,
                    referrerUrl: referrerUrl,
                    referrerDomain: referrerDomain,
                    userId: this.hub.userId,
                    ageRange: this.hub.ageRange,
                    paths: paths,
                    totalPaths: totalPaths,
                    createdAt: new Date(),
                    device: device
                }
            };
            if (value) {
                data.value = value;
            }

            if (appMode === 'production') {

                let r = db.collection(key).add(data);
                if (callback) {
                    r.then(() => {
                        callback();
                    });
                }

                // console.log(key, JSON.parse(JSON.stringify(data)));


                if (key.endsWith('-page')) {
                    ReactGA.pageview(decodeURIComponent(window.location.pathname));

                } else {
                    ReactGA.event({
                        category: source,
                        action: key
                    });
                }
            } else {
                // console.log(key, JSON.parse(JSON.stringify(data)));

                let r = db.collection(key).add(data);
                if (callback) {
                    r.then(() => {
                        callback();
                    });
                }
            }
        }

        this.state = {
            inited: false
        }

        // this.testScript(db)

    }

    testScript = async (db) => {

        let env = 'production'
        let source = 'people'

        let snapshot = await db.collection(`${env}--vote-results`)
        .where('source', '==', source)
        .get()

        let provinces = {}
        let parentMap = {}

        snapshot.forEach(it => {
            const doc = it.data()
            if (!doc.parentId) {
                parentMap[it.id] = doc
            }
        })

        snapshot.forEach(it => {
            const doc = it.data()
            if (!doc.province) {
                return
            }
            let parent = parentMap[doc.parentId]

            if (parent && (parent.crowdProvince != doc.province || parent.crowdZone != doc.zone || parent.crowdAmphoe != doc.amphoe || parent.crowdTambon != doc.tambon || parent.crowdUnit != doc.unit)) {
                return
            }


            let province = provinces[doc.province] = provinces[doc.province] || {}
            let amphoe = province[doc.amphoe] = province[doc.amphoe] || {}
            let tambon = amphoe[doc.tambon] = province[doc.tambon] || {}
            let unit = tambon[doc.unit] = tambon[doc.unit] || {}


            
            Object.keys(doc.results).forEach(num => {
                const votes = parseInt(doc.results[num], 10) || 0
                if (votes > 1500) {
                    return
                }
                unit[num] = unit[num] || []
                unit[num].push(votes)
            })

            let noVotes = parseInt(doc.noVote, 10) || 0
            let voideds = parseInt(doc.voided, 10) || 0
            unit['noVote'] = unit['noVote'] || []
            if (noVotes <= 1500) {
                unit['noVote'].push(noVotes)
            }
            unit['voided'] = unit['voided'] || []
            if (voideds <= 1500) {
                unit['voided'].push(voideds)
            }
        })


        let cleaned = {}
        Object.keys(provinces).forEach(p => {
            cleaned[p] = cleaned[p] || {}

            Object.keys(provinces[p]).forEach(a => {
                cleaned[p][a] = cleaned[p][a] || {}

                Object.keys(provinces[p][a]).forEach(t => {
                    cleaned[p][a][t] = cleaned[p][a][t] || {}

                    Object.keys(provinces[p][a][t]).forEach(u => {
                        cleaned[p][a][t][u] = cleaned[p][a][t][u] || {}

                        let counts = {}
                        Object.keys(provinces[p][a][t][u]).forEach(num => {
                            counts[num] = counts[num] || {}
                            provinces[p][a][t][u][num] && provinces[p][a][t][u][num].forEach && provinces[p][a][t][u][num].forEach(cnt => {
                                counts[num][cnt] = counts[num][cnt] || 0
                                counts[num][cnt]++
                            })
                        })
                        Object.keys(counts).forEach(num => {
                            let sorted = Object.keys(counts[num]).sort((a, b) => {
                                let n1 = counts[num][a]
                                let n2 = counts[num][b]
                                if (n1 > n2) {
                                    return -1
                                }
                                if (n2 > n1) {
                                    return 1
                                }
                                return 0
                            })

                            cleaned[p][a][t][u][num] = cleaned[p][a][t][u][num] || 0
                            if (sorted.length > 1) {
                                if (counts[num][sorted[0]] === counts[num][sorted[1]]) {
                                    cleaned[p][a][t][u][num] = 0
                                } else {
                                    cleaned[p][a][t][u][num] = parseInt(sorted[0], 10)
                                }
                            } else if (sorted.length === 1) {
                                cleaned[p][a][t][u][num] = parseInt(sorted[0], 10)
                            }
                        })
                    })
                })
            })
        })

    

        console.log('cleaned', cleaned)





        let provinceWinners = {}
        let unitResults = {}

        Object.keys(cleaned).forEach(p => {
            
            provinceWinners[p] = provinceWinners[p] || {}
            let sum = {}

            unitResults[p] = {children: {}, total: 0}
            Object.keys(cleaned[p]).forEach(a => {

                unitResults[p].children[a] = {children: {}, total: 0}
                Object.keys(cleaned[p][a]).forEach(t => {

                    unitResults[p].children[a].children[t] = {total: 0}
                    Object.keys(cleaned[p][a][t]).forEach(u => {

                        Object.keys(cleaned[p][a][t][u]).forEach(num => {
                            sum[num] = sum[num] || 0
                            sum[num] += cleaned[p][a][t][u][num]
                        })

                    })

                    unitResults[p].children[a].children[t].total = Object.keys(cleaned[p][a][t]).length
                    unitResults[p].children[a].children[t].children = cleaned[p][a][t]
                })

                unitResults[p].children[a].total = Object.keys(unitResults[p].children[a].children).reduce((i, k) => i + unitResults[p].children[a].children[k].total, 0);
            })

            unitResults[p].total = Object.keys(unitResults[p].children).reduce((i, k) => i + unitResults[p].children[k].total, 0);


            provinceWinners[p] = provinceWinners[p] || []
            let sortedKeys = Object.keys(sum).sort((a, b) => {
                let n1 = sum[a]
                let n2 = sum[b]
                if (n1 > n2) {
                    return -1
                }
                if (n2 > n1) {
                    return 1
                }
                return 0
            })

            provinceWinners[p] = sortedKeys.map(k => {
                return {
                    numbers: k,
                    votes: sum[k]
                }
            })
        })

        console.log('unitResults', unitResults)

        const provinceZones = require('../../../../functions/province-zone')
        const candidatesList = require('../../../../functions/candidates')
        const candidatesMap = {}

        
        candidatesList.forEach(c => {
            let province = candidatesMap[c.province] = candidatesMap[c.province] || {zones: {}}
            let zone = province.zones[c.zone] = province.zones[c.zone] || {candidatesMap: {}, candidates: []}
        
            zone.candidatesMap[c.no] = c
            zone.candidates.push(c)
        
            // parties.add(c.party)
        })

        let thailandTotal = 0

        let rProvinces = provinceZones.map(p => {
            let pName = p.province_name
            let pData = provinceWinners[p.province_name]
            if (!pData) {
                return {key: pName, total: 0, party: []}
            }


            let votes = {}
            pData.forEach(it => votes[it.numbers] = it.votes)

            const provinceCandidates = candidatesMap[pName].zones[''].candidates

            let candidatesList = [
                ...provinceCandidates,
                {no: 'noVote', name: 'noVote', party: 'noVote'},
                {no: 'voided', name: 'noVote', party: 'voided'}
            ]


            let voteCount = 0

            let parties = candidatesList.map(c => {

                const key = `${c.no}|${c.name}|${c.party}`
                voteCount += votes[c.no] || 0

                return {
                    key: key,
                    total: votes[c.no] || 0
                }


            }).filter(it => it.total)

            thailandTotal += voteCount

            return {
                key: pName,
                total: voteCount,
                party: parties
            }


        }).filter(it => it.total)

        let results = {
            province: rProvinces,
            total: thailandTotal
        }

        console.log('results', results)

    }

    getAppWrapperClass = () => {
        return ('page' + window.location.pathname
            .replace(/\//g, '-'))
            .replace(/-*$/g, '')
    }

    componentDidMount = () => {
        this.initData()
        window.addEventListener('popstate', () => {
            if (this.appWrapper) {
                this.appWrapper.className = this.getAppWrapperClass()
            }
        }, false)
    }

    // initData2 = async () => {

    //     let zonePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fpao-62.zone.csv?alt=media`)
    //     let candidatePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fpao-62.candidate.csv?alt=media`)

    //     try {
    //         let [
    //             zoneResp,
    //             candidateResp,
    //         ] = await Promise.all([zonePromise, candidatePromise])



    //         let zones = Papa.parse(zoneResp.data, {header: true}).data

    //         let treeZones = []
    //         let mapZones = {}

    //         zones.forEach(zone => {

    //             if (!mapZones[zone['จังหวัด']]) {
    //                 mapZones[zone['จังหวัด']] = {
    //                     id: `${zone['จังหวัด']}`,
    //                     name: zone['จังหวัด'],
    //                     children: []
    //                 }
    //                 treeZones.push(mapZones[zone['จังหวัด']])
    //             }

    //             if (!mapZones[zone['จังหวัด']][zone['เขต']]) {
    //                 mapZones[zone['จังหวัด']][zone['เขต']] = {
    //                     id: `${zone['จังหวัด']}--${zone['เขต']}`,
    //                     name: zone['เขต'],
    //                     children: []
    //                 }
    //                 mapZones[zone['จังหวัด']].children.push(mapZones[zone['จังหวัด']][zone['เขต']])
    //             }

    //             if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']]) {
    //                 mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']] = {
    //                     id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}`,
    //                     name: zone['อำเภอ'],
    //                     children: []
    //                 }
    //                 mapZones[zone['จังหวัด']][zone['เขต']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']])
    //             }

    //             if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']]) {
    //                 mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']] = {
    //                     id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}`,
    //                     name: zone['เทศบาล/ตำบล'],
    //                     children: []
    //                 }
    //                 mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']])

    //             }

    //             let station = `${[zone['ชื่อหน่วยเลือกตั้ง']]}`
    //             if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station]) {
    //                 mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station] = {
    //                     id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}--${station}`,
    //                     name: station
    //                 }
    //                 mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station])

    //             }
    //         })

    //         this.hub.zones = treeZones
    //         this.hub.zoneDetails = []
    //         this.hub.candidates = Papa.parse(candidateResp.data, {header: true}).data
    //         this.hub.candidates.forEach(item => item.party = 'พรรค' + item.party)

    //         // Util for all components
    //         this.hub.utils = new Utils(this.hub.zones, this.hub.zoneDetails);

    //         this.setState({inited: true})

    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    buildTreeZones = (zoneResp) => {
        let zones = Papa.parse(zoneResp.data, {header: true}).data

        let treeZones = []
        let mapZones = {}

        zones.forEach(zone => {

            if (!zone || !zone['ลำดับ']) {
                return
            }

            if (!mapZones[zone['จังหวัด']]) {
                mapZones[zone['จังหวัด']] = {
                    id: `${zone['จังหวัด']}`,
                    name: zone['จังหวัด'],
                    children: []
                }
                treeZones.push(mapZones[zone['จังหวัด']])
            }

            // if (!mapZones[zone['จังหวัด']][zone['เขต']]) {
            //     mapZones[zone['จังหวัด']][zone['เขต']] = {
            //         id: `${zone['จังหวัด']}--${zone['เขต']}`,
            //         name: zone['เขต'],
            //         children: []
            //     }
            //     mapZones[zone['จังหวัด']].children.push(mapZones[zone['จังหวัด']][zone['เขต']])
            // }

            if (!mapZones[zone['จังหวัด']][zone['อำเภอ']]) {
                mapZones[zone['จังหวัด']][zone['อำเภอ']] = {
                    id: `${zone['จังหวัด']}--${zone['อำเภอ']}`,
                    name: zone['อำเภอ'],
                    children: []
                }
                mapZones[zone['จังหวัด']].children.push(mapZones[zone['จังหวัด']][zone['อำเภอ']])
            }

            if (!mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']]) {
                mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']] = {
                    id: `${zone['จังหวัด']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}`,
                    name: zone['เทศบาล/ตำบล'],
                    children: []
                }
                mapZones[zone['จังหวัด']][zone['อำเภอ']].children.push(mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']])

            }

            let station = `${zone['ชื่อหน่วยเลือกตั้ง']} / หน่วยเลือกตั้งที่ ${zone['หน่วย']}`.replace(/#/g, '').trim()
            if (!mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station]) {
                mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station] = {
                    id: `${zone['จังหวัด']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}--${station}`,
                    name: station
                }
                mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']].children.push(mapZones[zone['จังหวัด']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station])

            }
        })

        
        return treeZones
    }

    initData = async () => {

        let zonePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fzone.compress.json?alt=media`)
        let candidatePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fcandidate.csv?alt=media`)
        // let xncPromise = axios.get(`https://s3-ap-southeast-1.amazonaws.com/vote62/data/xnc.csv`)
        // let zoneDetailPromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fzone.detail.json?alt=media`)
        let votePvtPromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/data%2Fvote-pvt.csv?alt=media`)
        let unitResultsPromise = axios.get(`https://us-central1-thai-vote-pao-62.cloudfunctions.net/unitResults?env=prod&source=people`)

        try {
            let [
                zoneResp,
                candidateResp,
                // zoneDetailResp,
                votePvtResp,
                unitResultsResp,
            ] = await Promise.all([
                zonePromise, 
                candidatePromise, 
                // zoneDetailPromise, 
                votePvtPromise, 
                unitResultsPromise
            ])


            this.hub.candidates = Papa.parse(candidateResp.data, {header: true}).data
            this.hub.candidates.forEach(item => item.party = 'พรรค' + item.party)
            // this.hub.xnc = Papa.parse(xncResp.data, {header: true}).data.reduce((obj, item) => {
            //     obj[item.party] = {
            //         snc: Boolean(parseInt(item.snc)),
            //         nnc: Boolean(parseInt(item.nnc))
            //     };
            //     return obj
            // }, {})
            // this.hub.zoneDetails = zoneDetailResp.data
            this.hub.xncTransparent = true;
            this.hub.votePvt = Papa.parse(votePvtResp.data, {header: true}).data.reduce(function(acc, cur, i) {
                acc[cur['province']] = cur;
                return acc;
            }, {});

            this.hub.unitResults = unitResultsResp.data

            // Util for all components
            this.hub.utils = new Utils(this.hub.zones, this.hub.zoneDetails);
            this.hub.zones = this.hub.utils.decode(zoneResp.data)

            this.setState({inited: true})

        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const inited = this.state.inited

        const setWrapper = ref => {
            if (!ref) {
                return
            }
            this.appWrapper = ref
            ref.className = this.getAppWrapperClass()
        }

        let submitKey = window.location.pathname;
        // let uploadKey = window.location.pathname + '?' +window.location.search;
        if (submitKey.indexOf('from') !== -1) {
            // uploadKey = 'crowdsource-vote-submit';

        }

        const app = (
            <Router>
                <div ref={setWrapper}>
                    <Header/>
                    
                    <Switch>
                        <Route exact path="/usages" component={props => <Overview {...props} hub={this.hub} />} />

                        {/* <Route exact path="/" render={() => <Redirect to="/visual" />} /> */}
                        <Route exact path="/" render={() => <Redirect to={this.hub.setting.isHomeVisual? `/visual`: `/candidates`} />} />

                        <Route exact path="/visual" render={props => <Motion {...props} hub={this.hub} />} />
                        <Route exact path="/visual/search" render={props => <VisualSearchProgress {...props} hub={this.hub} />} />
                        <Route exact path="/visual/table" render={props => <VisualTable {...props} hub={this.hub} />} />
                        <Route exact path="/visual/:source" render={props => <Motion {...props} hub={this.hub} />} />

                        <Route exact path="/candidates" component={props => <SearchForm {...props} hub={this.hub} />} />
                        <Route exact path="/candidates/:province" component={props => <LocationDetail {...props} hub={this.hub} />} />
                        <Route exact path="/candidates/:province/:no" component={props => <CandidateDetail {...props} hub={this.hub} />} />
                        <Route exact path="/candidates/:province/:no/image" component={props => <CandidateImage {...props} hub={this.hub} />} />
                        <Route exact path="/candidates/:province/:no/print" component={props => <CandidatePrint {...props} hub={this.hub} />} />
                        
                        <Route exact path="/news" component={props => <News {...props} hub={this.hub} />} />

                        <Route exact path="/crowdsource" render={props => <CrowdSourcing {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/overview" render={props => <CrowdSourcingOverview {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/digitize" render={props => <CrowdSourcingRandom {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/upload" render={props => <CrowdSourcingVoteSubmit key={'upload-path'} {...props} hub={this.hub}/>} />
                        <Route exact path="/crowdsource/digitize/:id" render={props => <CrowdSourcingVoteSubmit key={window.location.search.indexOf('from') === -1 ? 'upload-path': window.location.pathname} {...props} hub={this.hub} page={2}/>} />
                        <Route exact path="/crowdsource/thank-you" render={props => <CrowdSourcingThankYou {...props} />} />
                        <Route exact path="/crowdsource/search" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province/:zone" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province/:zone/:location" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/result" render={props => <CrowdSourcingZoneResult key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/result/:province" render={props => <CrowdSourcingZoneResult key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/result/:province/:zone" render={props => <CrowdSourcingZoneResult key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/result/:province/:zone/:location" render={props => <CrowdSourcingZoneResult key={window.location.pathname} {...props} hub={this.hub} />} />


                        <Route exact path="/admin/crowdsource" render={props => <AdminCrowdSourcing {...props} hub={this.hub} />} />

                        <Route component={NoMatch} />
                    </Switch>

                </div>
            </Router>
        )
        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                {inited ? app : loader}
            </div>
        )
    }
}

export default App;

const wrapper = document.getElementById("root");
ReactDOM.render(<App/>, wrapper);

// const render = Component => {
//     ReactDOM.render(
//         <AppContainer>
//             <App />
//         </AppContainer>,
//         wrapper
//     );
// }
//
// render(App)

// hot.accept('./App.jsx', () => {
//     render(<App />, wrapper);
// })

/*
const HotApp = hot(App)

export default HotApp;

const wrapper = document.getElementById("root");
ReactDOM.render(<HotApp/>, wrapper);
 */

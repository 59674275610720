const popularSort = (a, b) => {
    return (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)
}

export default class Utils {
    constructor(zones, zoneDetails) {
        this.zones = zones;
        this.zoneDetails = zoneDetails;
    }

    getDeepLocation(province, zone) {
        let detail = '';

        let renderLines = [];

        this.zoneDetails.forEach(i => {
            if (i[1] === province) {
                let lines = i[2][parseInt(zone)-1];
                lines.forEach(line => {
                    if (parseInt(line.charAt(0))) {
                        renderLines.push(line);
                    } else {
                        if (renderLines.length == 0) {
                            renderLines[0] = '';
                        }
                        renderLines[renderLines.length-1] += ' ' + line
                    }
                });
                detail = renderLines.join(' — ')
                return false;
            }
        });

        if (!detail) {
            let items = [];
            this.zones.forEach(level1 => {
                if (level1.name === province) {
                    level1.children.forEach(level2 => {
                        level2.children.forEach(level3 => {
                            if (parseInt(level3.zone) === parseInt(zone)) {
                                items.push(level3.name);
                            }
                        });
                    });

                    return false;
                }
            });
            detail = items.join(' ');
        }

        return detail;
    }

    processDataSource(dataSource) {

        let partyMP = {};
        let partyScore = {};

        // Faster by index map and ordering

        if (dataSource.popular) {
            dataSource.popular.sort(popularSort);
            dataSource.popular.forEach((party) => {
                if (party.key !== 'noVote' && party.key !== 'voided') {
                    partyScore[party.key] = party.total;
                }
            });
        }


        dataSource.succeedTotal = 0;
        dataSource.noVoteTotal = dataSource.noVoteTotal || 0;
        dataSource.voidedTotal = dataSource.voidedTotal || 0;
        dataSource.goodVoteTotal = 0;


        if (dataSource.province) {
            dataSource.province = dataSource.province.reduce((obj1, province) => {

                
                province.party.sort(popularSort);

                // provide score for party list
                let winnerParty = null;
                let noVote = null;
                let voided = null;

                let onlyParty = province.party.filter((party, i) => {
                    if (party.key === 'noVote') {
                        noVote = party;
                    }
                    if (party.key === 'voided') {
                        voided = party;
                    }
                    return party.key !== 'noVote' && party.key !== 'voided';
                });

                if (onlyParty.length >= 1 && noVote && noVote.total >= onlyParty[0].total) {
                    // re vote
                } else if (onlyParty.length >= 2 && onlyParty[0].total === onlyParty[1].total) {
                    // draw
                } else if (onlyParty.length >= 1 && onlyParty.filter(p => p.total > 0).length >= 1) {
                    winnerParty = onlyParty[0];
                }
                province.winnerParty = winnerParty;
                province.succeed = province.party.filter(it => it.total > 0).length > 0

                if (province.succeed) {
                    dataSource.succeedTotal++;
                }
                if (noVote) {
                    dataSource.noVoteTotal += noVote.total;
                }
                if (voided) {
                    dataSource.voidedTotal += voided.total;
                }
                dataSource.goodVoteTotal += onlyParty.reduce((a, b) => a + b.total, 0);


                if (winnerParty) {
                    if (!partyMP[winnerParty.key]) {
                        partyMP[winnerParty.key] = 0;
                    }
                    partyMP[winnerParty.key]++;
                }


                province.party.sort(popularSort);
                obj1[province.key] = province;

                return obj1
            }, {});
        }

        // calculate party list

        const totalScore = Object.keys(partyScore).reduce((a, key) => a + partyScore[key], 0);
        const score4Rep = totalScore / 500;

        const calPartyList = () => {

            let partyShouldMP = [];

            for (const key of Object.keys(partyScore)) {

                const zoneTotal = partyMP[key] || 0;
                const score = partyScore[key];
                const partyRep = score4Rep ? score / score4Rep : 0;
                const partyRepCeilling = Math.floor(partyRep);
                const partyListCount = Math.max(0, partyRepCeilling - zoneTotal);


                const partyName = key.replace('พรรค', '');

                partyShouldMP.push({
                    key: key,
                    color: Party[partyName].color,
                    shortName: Party[partyName].shortName,
                    total: partyRep,
                    partyListCount: partyListCount,
                    moreTotal: partyListCount,
                    zoneTotal: zoneTotal,
                    allTotal: zoneTotal + partyListCount,
                    score: score
                });
            }
            partyShouldMP.sort(popularSort);

            return partyShouldMP;
        }

        let partyShouldMP = calPartyList();
        let sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);

        if (sumPartyList > 150) {
            let popularPartyListDecimal = [];
            let partyShouldMPMap = {};

            partyShouldMP.forEach(p => {
                let partyListCount = p.partyListCount*150/sumPartyList;
                let partyListDecimal = partyListCount - Math.floor(partyListCount);
                p.partyListCount = Math.floor(partyListCount);
                p.moreTotal = p.partyListCount; // alias
                partyShouldMPMap[p.key] = p;
                popularPartyListDecimal.push({key: p.key, total: partyListDecimal})
            });
            popularPartyListDecimal.sort(popularSort);

            let remainingMP = 150 - partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);
            popularPartyListDecimal.forEach(p => {
                if (remainingMP > 0) {
                    partyShouldMPMap[p.key].partyListCount++;
                    partyShouldMPMap[p.key].moreTotal = partyShouldMPMap[p.key].partyListCount;
                    remainingMP--;
                }

            });


        } else {
            // TODO: increase MP by decimal
        }
        sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);


        let remainingMP = 150;
        partyShouldMP.forEach(party => {
            party.finalMoreTotal = Math.min(party.moreTotal, remainingMP);
            party.allTotal = party.zoneTotal + party.finalMoreTotal;
            remainingMP -= party.finalMoreTotal;
        });


        dataSource.partyShouldMP = partyShouldMP;

    }

    decode(data) {
        var lookup = [],
          words = [],
          infoLookup = {},
          t;
      
        if (data.lookup && data.words) {
          // compact with dictionary and lookup
          infoLookup = data.info;
          lookup = data.lookup.split("|");
          words = data.words.split("|");
          data = data.data;
        }
      
        t = function(text) {
          function repl(m) {
            var ch = m.charCodeAt(0);
            return words[ch < 97 ? ch - 65 : 26 + ch - 97];
          }
      
          if (typeof text === "number") {
            text = lookup[text];
          }
          return text.replace(/[A-Z]/gi, repl);
        };
      
        let infoT = function(text) {
          function repl(m) {
            return infoLookup[m];
          }
      
          return text.replace(/[A-Z]/gi, repl);
        };
      
        // decompacted database in hierarchical form of:
        // [["province",[["amphur",[["district",["zip"...]]...]]...]]...]
        var output = [];
        data.map(function(provinces) {
          const pName = t(provinces[0]);
          const p = {
            id: pName,
            name: pName,
            children: [],
          };
          output.push(p);
          provinces[1].map(function(amphoes) {
            const aName = t(amphoes[0]);
            const a = {
              id: `${pName}--${aName}`,
              name: aName,
              children: [],
            };
            p.children.push(a);
            amphoes[1].map(function(districts) {
              const dName = t(districts[0]);
              const d = {
                id: `${pName}--${aName}--${dName}`,
                name: dName,
                children: [],
              };
              a.children.push(d);
              a[dName] = d;
              districts[1].map(function(info) {
                const txt = infoT(info);
                const txtAry = txt.split("|");
                const uName = `${txtAry[1].replace(/#/g, '')} / หน่วยเลือกตั้งที่ ${txtAry[0]}`;
                var entry = {
                  id: `${pName}--${aName}--${dName}--${uName}`,
                  name: uName,
                  no: txtAry[0],
                  detail: txtAry[1],
                  zone: txtAry[2],
                };
                d.children.push(entry);
              });
            });
          });
        });
        return output;
    }
}
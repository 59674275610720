import React, {Component} from "react";
import posed, { PoseGroup } from 'react-pose';
import Province from "../data/provinces.json";
import Council from '../data/council.csv'
import ReactTooltip from 'react-tooltip'

function chunkArray(array, chunk) {
    var i,j,out=[];
    for (i=0,j=array.length; i<j; i+=chunk) {
        out.push(array.slice(i,i+chunk));
    }
    return out;
}

function transpose(a) {
    
    return a.length == 0? a: Object.keys(a[0]).map(function(c) {
        return a.map(function(r) { return r[c]; });
    });
}

function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

const CandidateUnit = React.forwardRef((props, ref) => {
    let style = {};

    if (props.mp && props.mp.party &&props.mp.party.color) {
        style.backgroundColor = props.mp.party.color;
    }

    return (
        <div ref={ref} {...props} className={`candidate-unit-wrapper${props.mp.active? '': ' invisible'}${props.mp.drop? ' drop': ''}`}  data-toggle="tooltip" data-placement="top" data-tip={props.mp.location && props.mp.location.province}>
            <div className={`candidate-unit`}>
                <div className={`hexagon ${props.mp.location && props.mp.location.small? `small`: ``}`}>
                    {/* <div style={style} className="hexagon-before" /> */}
                    <div style={style} className={`hexagon-content`} />
                    {/* <div style={style} className="hexagon-after" /> */}
                </div>
            </div>
        </div>
    )
});

const CandidateUnitPosed = posed(CandidateUnit)({
    flip: {
        transition: {
            duration: 1000,
            ease: 'easeInOut'
        }
    }
});


export default class CandidateMatrix extends Component {
    constructor(props) {
        super(props);
        this.hub = props.hub;

        this.state = {
            matrixMap: {},
            matrix: {geoPartyList: [], geo: [], partyList:[], council: []}
        };

        this.matrixMounted = false;

        this.initialMatrix = this.initialMatrix.bind(this);
        this.mountMatrix = this.mountMatrix.bind(this);
        this.initialMatrix();

    }

    shouldComponentUpdate(nextProps, nextState) {
        const should = nextProps.forceUpdate || this.props.paradeMode !== nextProps.paradeMode || (!this.props.dataSource && nextProps.dataSource) || (nextProps.dataSourceCode !== this.props.dataSourceCode) || !isEquivalent(nextProps.selectedParty, this.props.selectedParty);
        return should;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.dataSourceCode !== this.props.dataSourceCode) {
            this.matrixMounted = false;
        }

        if (nextProps.dataSource && !this.matrixMounted) {
            console.log('matrixMounted');
            this.initialMatrix(); // TODO: improve performance, not call initialMatrix
            this.mountMatrix(nextProps);
            this.matrixMounted = true;
        }

        let matrix = this.state.matrix['council'];
        if (Object.keys(nextProps.selectedParty).length === 0) {
            matrix.filter(mp => mp && mp.active).forEach(mp => {
                mp.drop = false;
            });
        } else {
            matrix.filter(mp => mp.active).forEach(mp => {
                mp.drop = !mp.party || !(nextProps.selectedParty[mp.party.key] || false);
            });
        }
    }

    getMapText(mode) {
        return ({
            geoPartyList: `
| ooo      |
|oooo      |
|oooo ooo  |
|ooooooooo |
| oooooooo |
| ooooooooo|
| ooooooooo|
|  ooooo.  |
|  o..o.   |
|  o   .   |
|  o       |
|  .       |
| o        |
| o        |
| .        |
|.o        |
|ooo       |
| .o       |
|  o       |
|   .      |
`,
            council: Council
        })[mode];
    }


    getZones() {
        return ["58", "50", "57", "51", "52", "56", "55", "63", "64", "54", "53", "42", "43", "38", "62", "66", "60", "65", "67", "39", "41", "47", "48", "61", "18", "15", "16", "36", "40", "46", "49", "71", "73", "14", "17", "30", "44", "45", "35", "37", "70", "74", "72", "19", "26", "31", "32", "33", "34", "75", "12", "13", "24", "25", "27", "76", "11", "20", "21", "22", "77", "23", "86", "85", "84", "80", "82", "83", "81", "91", "92", "93", "90", "94", "95", "96"]
    }

    initialMatrix() {
        this.activeList = [];
        this.inactiveList = [];

        const geoPartyListMode = 'geoPartyList';
        const geoPartyListMapText = this.getMapText(geoPartyListMode);

        let matrixMap = {};
        let matrix = {
            geoPartyList: [],
            geo: [],
            partyList: [],
            council: []
        };

        let zones = this.getZones();

        let inactiveIndex = 0;
        let activeIndex = 0;
        let partyListIndex = 0;


        // console.log(partyShouldMP);
        geoPartyListMapText.trim()
            .replace(/\|/g, '')
            .split('\n')
            .forEach((line, i) => line.split('').forEach((col, j) => {

                const active = col.trim();
                let provinceZone;
                let id;
                let location;

                if (active) {
                    provinceZone = zones.shift();
                    if (provinceZone) {
                        id = provinceZone.split('_');
                        let province = Province[id[0]];

                        id = province

                        location = {
                            province: province,
                            small: col == '.'
                        }

                    } else {
                        id = `party-list/${partyListIndex+1}`;
                        partyListIndex++;

                        // partyList
                    }

                } else {
                    id = `inactive/${inactiveIndex + 1}`;
                }

                let mp = {
                    id: id,
                    active: active,
                    location: location
                };


                matrixMap[mp.id] = mp;
                matrix['geoPartyList'].push(mp);

                if (provinceZone || !active) { // party zone
                    matrix['geo'].push(mp);
                } else { // party list
                    matrix['partyList'].push(mp);
                }

                if (mp.active) {
                    this.activeList.push(mp);
                    activeIndex++;
                } else {
                    this.inactiveList.push(mp);
                    inactiveIndex++
                }
            }));

        matrix['partyList'] = chunkArray(matrix['partyList'],25);
        matrix['partyList'] = transpose(matrix['partyList']);
        matrix['partyList'] = [].concat.apply([], matrix['partyList']);

        this.state.matrix = matrix;
        this.state.matrixMap = matrixMap;
    }


    mountMatrix(props) {

        props = props || this.props;


        // party data generate
        let pli = 0;
        const Party = props.Party;

        let partyShouldMP = props.dataSource && JSON.parse(JSON.stringify(props.dataSource.partyShouldMP));
        let partyAllMP;

        if (partyShouldMP) {
            partyShouldMP.sort((a, b) => (a.finalMoreTotal > b.finalMoreTotal) ? -1 : ((b.finalMoreTotal > a.finalMoreTotal) ? 1 : 0));

            // partyAllMP = [].concat(partyShouldMP);
            // partyAllMP.sort((a, b) => (a.allTotal > b.allTotal) ? -1 : ((b.allTotal > a.allTotal) ? 1 : 0));
            partyAllMP = [].concat(partyShouldMP).reduce((obj, p) => {obj[p.key] = p; return obj;}, {});
        }


        let dataSource = props.dataSource;

        const buildMP = (mp) => {

            if (!dataSource || !dataSource.province) {
                return null;
            }

            const [provinceName, zoneNumber] = mp.id.split('/');
            let party = null;
            let zone = null;



            if (provinceName === 'inactive') { // ignore
                // do nothing
                return null;

            } else if (provinceName === 'party-list' && partyShouldMP) { // party list
                // return data of party list no of party but now, we don't have data of party list

                while (partyShouldMP[pli] && partyShouldMP[pli].finalMoreTotal <= 0) {
                    pli++;
                }

                if (partyShouldMP[pli]) {
                    party = partyShouldMP[pli];
                    partyShouldMP[pli].finalMoreTotal--;
                }

            } else { // party zone
                if (!dataSource) return null;


                let p = dataSource.province[provinceName];
                if (!p) return null;

                // let z = p.zones[zoneNumber];
                // if (!z) return null;

                // zone = z;
                party = p.winnerParty;

                if (!party) return null;
            }

            if (!party) {
                console.log(provinceName, pli)
                return
            }

            const partyName = party.key.split('|')[2].replace('พรรค', '');
            party.color = Party[partyName].color;
            party.shortName = Party[partyName].shortName;

            mp.party = party;
            mp.zone = zone;
        };

        this.state.matrix['geo'].forEach(mp => {buildMP(mp);});

        this.state.matrix['partyList'].forEach(mp => {buildMP(mp);});

        let partyListMatrix = [].concat(this.state.matrix['partyList']);
        partyListMatrix = chunkArray(partyListMatrix,6);
        partyListMatrix = transpose(partyListMatrix);
        partyListMatrix = [].concat.apply([], partyListMatrix);

        this.state.matrix['geoPartyList'] = this.state.matrix['geo'].concat(partyListMatrix);


        // council ordering map
        const councilMode = 'council';
        const councilMapText = this.getMapText(councilMode);

        this.activeList.sort((a, b) => {
            let aKey = (a.party && a.party.key) || '';
            let bKey = (b.party && b.party.key) || '';

            return (aKey > bKey) ? 1 : ((bKey > aKey) ? -1 : '');
        });

        // console.log('partyAllMP', partyAllMP)

        if (false && partyAllMP) {
            this.activeList.forEach(mp => {
                mp.allTotal = mp.party? partyAllMP[mp.party.key].allTotal: 0;
            });

            this.activeList.sort((a, b) => (a.allTotal > b.allTotal) ? -1 : ((b.allTotal > a.allTotal) ? 1 : 0));
        }

        councilMapText
            .split('\n')
            .forEach((line, i) => line.split(',').forEach((col, j) => {
                const active = col.trim();
                const mp = active? this.activeList[parseInt(col)-1]: this.inactiveList.shift();

                this.state.matrix[councilMode].push(mp);
            }));
        this.activeList = [];


    }


    render() {

        if (false && this.props.animate && this.matrixMounted) {
            return (
                <PoseGroup>
                    {this.state.matrix[this.props.paradeMode].map((mp, i) =>
                        mp.active ? <CandidateUnitPosed key={mp.id} mp={mp} onPoseComplete={this.props.onCandidateUnitPoseComplete} onClick={() => {this.props.onCandidateUnitClick(mp.location)}}/> : <CandidateUnit key={mp.id} mp={mp}/>
                    )}
                </PoseGroup>
            );
        } else {
            return (
                <>
                    {this.state.matrix[this.props.paradeMode].map((mp, i) => mp.active ? <CandidateUnit key={mp.id} mp={mp} onClick={() => {this.props.onCandidateUnitClick(mp.location)}} /> : <CandidateUnit key={mp.id} mp={mp}/>)}
                    <ReactTooltip />
                </>
            )
        }
    }
}
import React from 'react'
import Link from 'react-router-dom/es/Link';
import CrowdSourcingZoneDetail from './CrowdSourcingZoneDetail.jsx'
import VisualUnitResult from './VisualUnitResult.jsx';
import CandidateMatrix from './CandidateMatrix.jsx'
import Party from "../data/party.json";

export default class CrowdSourcingZoneResult extends CrowdSourcingZoneDetail {

    key = `result`

    constructor(props) {
        super(props)

        this.pageCode = 'crowdsource-zone-result';
        this.hub = props.hub;

        let {province, zone, location} = props.match.params;

        if (location) {
            let matches = [];
            this.searchLocation([`id-${location}`], this.hub.zones, matches, 1);
            location = matches[0] || null;
        }

        this.state = {

            redirect: false,

            province: province,
            results: [],
            loading: true,

            // Location
            location: location,
            locationMatches: [],
            dataSource: null,
        };
        
        this.state.queryLocation = this.getLocationSuggestionValue(this.getMergeLocation());
        // this.updateDataSource()

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        let value = false;
        if (this.state.province && this.state.zone) {
            value = {province: this.state.province, zone: this.state.zone};
        }
        this.hub.logStat(this.pageCode, 'crowdsource-zone-result-page', value);
        this.updateLocation(this.state.location);
        this.updateDataSource();

    }


    updateLocation = async () => {

        this.setState({
            loading: true
        });

        let mLocation = this.getMergeLocation();
        let unitResult = {}

        if (!this.hub.setting.isInputOpen) {

            console.log('updateLocation')

            this.setState({
                results: {},
                loading: false,
                location: mLocation,
            });

        } else if (!mLocation || (!mLocation.province && !mLocation.zone)) { // search page


            this.setState({
                results: unitResult,
                loading: false,
                location: mLocation,
            });

        } else if (!mLocation.zone) { // province page

            let callable = await this.hub.functions.httpsCallable('cleanedVoteResultsCallable')
            let result = await callable({env: this.hub.appMode, source: 'people'})
    
            if (result.data[mLocation.province]) {
                unitResult = result.data[mLocation.province]
            }

            console.log('unitResult', unitResult)

            this.setState({
                results: unitResult,
                loading: false,
                location: mLocation,
            });

        } else { // zone page


            this.setState({
                results: unitResult,
                loading: false

            });
        }

    }

    
    async getDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    async updateDataSource() {

        if (!this.hub.setting.isInputOpen) {
            this.setState({dataSource: {
                goodVoteTotal: 0,
                noVoteTotal: 0,
                partyShouldMP: [],
                province: {},
                succeedTotal: 0,
                total: 0,
                voidedTotal: 0,
            }})
            return
        }

        let dataSource;
        let cache = JSON.parse(localStorage.getItem(`cache-dataSource-people`));
        let hit = cache && (Date.now() - parseInt(cache.timestamp)) < 1*60*1000;

        if (hit) { // TODO: get data source from cache every 1 minute (success) or when receive realtime message from firebase

            dataSource = cache.value;

        } else { // build data source

            dataSource = await this.getDataSource()

            this.hub.utils.processDataSource(dataSource)

        }

        this.setState({dataSource: dataSource})
        console.log('this.state.dataSource', dataSource)
    }

    unitItem(unit, result) {

        // console.log('result', result, unit)

        let hasResult = result && result[unit.name]
        if (false) {
            return (
                <div key={unit.id} className="monitoring-status empty">
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ backgroundColor: "transparent" }}>
                        <div className="rounded-circle" style={{ backgroundColor: "white" }}>&nbsp;</div>
                    </div>
                </div>
            )
        } else if (!hasResult) {
            return (
                <Link key={unit.id} to={`/crowdsource/upload?location=${unit.id}`} className="monitoring-status empty" data-toggle="tooltip" data-placement="top" data-tip={unit.name}>
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center">
                        <div className="rounded-circle">&nbsp;</div>
                    </div>
                </Link>
            )
        } else  {
            return (
                <Link key={unit.id} to={`/visual/search?location=${unit.id}`} className="monitoring-status has-result" data-toggle="tooltip" data-placement="top" data-tip={unit.name}>
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ border: "1px solid white" }}>
                        <img className="img-fluid d-block d-none" src="/static/images/ic-check-white.png" srcSet="/static/images/ic-check-white.png 1x, /static/images/ic-check-white@2x.png 2x" />
                    </div>
                </Link>
            )
        }
    }

    renderPageContent = () => {

        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        let mLocation = this.getMergeLocation();


        if (!mLocation || (!mLocation.province && !mLocation.zone)) { // search page
            
            return (
                <div className="bg-light-grey flex-grow-1">
                    <div className={`candidate-motion-container ${this.state.paradeMode}`}>
                        <div className={`candidate-motion-wrapper px-0`} style={{marginLeft: '18%', marginRight: '18%'}}>
                            {this.state.dataSource || true?
                            <CandidateMatrix
                                hub={this.hub}
                                // forceUpdate={true}
                                paradeMode={`geoPartyList`}
                                dataSource={this.state.dataSource}
                                dataSourceCode={`people`}
                                Party={Party}
                                // onCandidateUnitPoseComplete={this.onCandidateUnitPoseComplete}
                                onCandidateUnitClick={this.onLocationSelected}
                                selectedParty={{}}
                            />
                            : ``}
                        </div>
                    </div>
                </div>
            )

        } else if (!mLocation.zone) { // province page

            let amphoes = this.hub.zones.filter(item => item.name == mLocation.province)[0].children

            return (
                <div className={``}>
                    {this.state.loading ? loader : <VisualUnitResult hub={this.hub} amphoes={amphoes} unitResult={this.state.results} />}
                </div>
            )
        } else { // zone page
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={`container`}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h3 className={`_fw-med _fs-36 _lh-80 mb-0`}>
                                    {this.state.province} เขต {this.state.zone}
                                </h3>
                            </div>
                            <div className={`d-flex flex-row flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((result, i) => this.renderAlbum(result, i))
                                    :
                                    <div className={`mt-5`}><h3>ยังไม่มีข้อมูลดิบที่เขตนี้</h3></div>
                                }
                            </div>
                        </div>

                        <div className={`crowd-action-wrapper mt-4 mx-auto text-center`}>
                            <div className={``}>
                                <Link className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1`} to={`/crowdsource/digitize`}><img src="/static/images/ic-send-result.png" srcSet="/static/images/ic-send-result.png 1x, /static/images/ic-send-result@2x.png 2x" width="30px" /> กรอกคะแนน</Link>
                                <Link className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1`} to={`/crowdsource/upload`}><img src="/static/images/ic-report.png" srcSet="/static/images/ic-report.png 1x, /static/images/ic-report@2x.png 2x" width="30px" /> อัพโหลดภาพ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

}
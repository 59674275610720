import React, {useMemo} from 'react'
import PageComponent from './PageComponent.jsx'
import {useDropzone} from 'react-dropzone'
import Pica from 'pica/dist/pica.js'
import uuidv4 from 'uuid/v4'

import firebase from 'firebase/app'
import 'firebase/storage'
import Webcam from 'react-webcam'
import Slider from 'react-slick'
import PinceZoomPan from 'react-responsive-pinch-zoom-pan'
import Modal from 'react-awesome-modal'
import Link from 'react-router-dom/es/Link'
import EXIF from 'exif-js'
import axios from 'axios'
import ReportAbnormalModal from './ReportAbnormalModal.jsx'
import GoogleMapReact from 'google-map-react'
import Speech from '../speech'
import CrowdSourcingOpenMask from './CrowdSourcingOpenMask.jsx'

const baseStyle = {
    height: 120,
    borderWidth: 2,
    borderColor: '#c5c5c5',
    borderStyle: 'dashed',
    borderRadius: 20
};

const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#c5c5c5',
    backgroundColor: '#EFEFEF'
};

const acceptStyle = {
    borderStyle: 'solid',
    borderColor: '#00e676'
};

const rejectStyle = {
    borderStyle: 'solid',
    borderColor: '#ff1744'
};

/**
 * @see: https://github.com/brion/min-wasm-fail/blob/master/min-wasm-fail.js
 * @see: https://support.scandit.com/hc/en-us/articles/115003947711-Known-issues-with-mobile-Safari-on-iOS-11-2-2-and-11-2-5
 */
function testSafariWebAssemblyBug () {
    let bin = new Uint8Array([0, 97, 115, 109, 1, 0, 0, 0, 1, 6, 1, 96, 1, 127, 1, 127, 3, 2, 1, 0, 5, 3, 1, 0, 1, 7, 8, 1, 4, 116, 101, 115, 116, 0, 0, 10, 16, 1, 14, 0, 32, 0, 65, 1, 54, 2, 0, 32, 0, 40, 2, 0, 11])
    let mod = new window.WebAssembly.Module(bin)
    let inst = new window.WebAssembly.Instance(mod, {})

    // test storing to and loading from a non-zero location via a parameter.
    // Safari on iOS 11.2.5 returns 0 unexpectedly at non-zero locations
    return (inst.exports.test(4) !== 0)
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

let pica = null
if (window.WebAssembly && testSafariWebAssemblyBug()) {
    pica = Pica({ features: [ 'js', 'wasm', 'ww', 'cib' ], tile: 256, concurrency: 1, idle: 500})
} else {
    pica = Pica({ features: [ 'js', 'cib', 'ww' ], tile: 256, concurrency: 1, idle: 500})
}

const MAX_WIDTH = 1024
const MAX_HEIGHT = 1024

function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DropZone = (props) => {
    const dz = useDropzone({accept: 'image/*', onDropAccepted(files, event) {
        if (props.onFilesDrop) {
            props.onFilesDrop(files, event)
        }
    }});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(dz.isDragActive ? activeStyle : {}),
        ...(dz.isDragAccept ? acceptStyle : {}),
        ...(dz.isDragReject ? rejectStyle : {})
    }), [
        dz.isDragActive,
        dz.isDragReject
    ]);

    return (
        <div className="p-1 d-flex align-items-center justify-content-center flex-column upload-file"
             {...dz.getRootProps({style})}>
            <input {...dz.getInputProps()} />
            <i className="fa fa-image fa-lg mt-2"></i>
            <div className="mt-2">อัปโหลดภาพ</div>
            <div className="small">สามารถอัปโหลดได้มากกว่า 1 รูป</div>
            
        </div>
    );
}

const fixOrientation = (canvas, ctx, orientation) => {
    const width = canvas.width
    const height = canvas.height

    switch (Number(orientation)) {
        // explained here: https://i.stack.imgur.com/6cJTP.gif
        case 1:
            break;

        case 2:
            ctx.translate(width, 0);
            ctx.scale(-1, 1);
            break;

        case 3:
            ctx.translate(width, height);
            ctx.rotate((180 / 180) * Math.PI); // 180/180 is 1? No shit, but how else will you know its need 180 rotation?
            break;

        case 4:
            ctx.translate(0, height);
            ctx.scale(1, -1);
            break;

        case 5:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate((90 / 180) * Math.PI);
            ctx.scale(1, -1);
            break;

        case 6:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate((90 / 180) * Math.PI);
            ctx.translate(0, -height);
            break;

        case 7:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate((270 / 180) * Math.PI);
            ctx.translate(-width, height);
            ctx.scale(1, -1);
            break;

        case 8:
            canvas.width = height;
            canvas.height = width;
            ctx.translate(0, width);
            ctx.rotate((270 / 180) * Math.PI);
            break;

        default:
            break;
    }
}

const resizeImage = (imageUrl, name, mimetype) => {
    return new Promise((resolve) => {
        let newImage = new window.Image()

        newImage.src = imageUrl
        newImage.addEventListener('load', () => {
            const canvas = document.createElement('canvas')

            let width = newImage.naturalWidth
            let height = newImage.naturalHeight

            let toWidth, toHeight
            if (MAX_WIDTH < width) {
                toWidth = MAX_WIDTH
                toHeight = (MAX_WIDTH / width) * height
            } else if (MAX_HEIGHT < height) {
                toHeight = MAX_HEIGHT
                toWidth = (MAX_HEIGHT / height) * width
            } else {
                resolve({
                    resized: false
                })
                return
            }

            canvas.width = toWidth
            canvas.height = toHeight

            EXIF.getData(newImage, () => {
                const orientation = EXIF.getTag(newImage, 'Orientation')
                const ctx = canvas.getContext('2d')

                fixOrientation(canvas, ctx, orientation)
                ctx.drawImage(newImage, 0, 0, toWidth, toHeight)
                canvas.toBlob(blob => resolve({resized: true, newFile: blob}), 'image/jpeg', 1)
            })
        })
    })
}

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    let ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});

}

function imgToDataURL(imgRef, mimetype) {
    let canvas = document.createElement('canvas')
    canvas.width = imgRef.naturalWidth
    canvas.height = imgRef.naturalHeight
    canvas.getContext('2d').drawImage(imgRef, 0,0)
    return canvas.toDataURL(mimetype)
}

export default class CrowdSourcingVoteSubmit extends PageComponent {
    constructor(props) {
        super(props)
        this.hub = props.hub

        const { id } = this.props.match.params

        let mode = 'upload'
        if (props.page === 2 && id) {
            mode = 'details'
        }
        this.mode = mode

        this.provinces = this.hub.zones.map(z => ({id: z.id, name: z.name, children: z.children})).sort((a, b) => {
            const x = a.name.replace(/^([เแโไใ])(.)/, '$2$1')
            const y = b.name.replace(/^([เแโไใ])(.)/, '$2$1')

            if (x > y) {
                return 1
            }
            if (y > x) {
                return -1
            }

            return 0
        })
        this.provincesMap = {}
        this.provinces.forEach(p => {
            this.provincesMap[p.name] = p.id
        })

        let savedState
        try {
            savedState = {...JSON.parse(window.localStorage.getItem('voteResult') || '{}')}
            console.log('savedState', savedState)
            if (savedState.reportId !== id) {
                savedState = {...savedState, files: [], province: '', amphoe: '', tambon: '', zone: '', unit: '', results: []}
            }
        } catch (e) {
            console.log(e)
            savedState = {}
        }
        this.savedState = savedState
        this.candidateListRef = null
        this.previewBoxRef = null
        this.sliderRef = null
        this.zoneData = {}

        this.pageCode = this.mode === 'upload' ? 'crowdsource/upload' : `crowdsource/digitize`
        this.urlUnlisten = null

        this.from = getUrlParameter('from');
        this.fromLocation = this.from.split('/');

        this.reportAbnormalModal = React.createRef()

        this.defaultLocation = {lat: 16.402498, lng: 102.457879}


        let province = ''
        let amphoe = ''
        let tambon = ''
        let unit = ''
        let selectedLocation = getUrlParameter('location')


        if (selectedLocation) {
            selectedLocation = selectedLocation.split('--')
            province = selectedLocation[0]
            amphoe = selectedLocation[1]
            tambon = selectedLocation[2]
            unit = selectedLocation[3]
            console.log('location', selectedLocation)
        }

        this.state = {
            page: mode === 'details' ? 2 : 1,
            province: province,
            provinces: [],
            amphoe: amphoe,
            amphoes: [],
            tambon: tambon,
            tambons: [],
            zone: '',
            unit: unit,
            units: [],
            results: {},
            noVote: 0,
            total: 0,
            voided: 0,
            eligible: 0,
            files: [],
            name: window.localStorage.getItem('name') || '',
            contact: window.localStorage.getItem('contact') || '',
            currentLocation: null,
            selectedFile: null,
            showWebcam: false,
            reportId: '',
            modalVisible: false,
            modalTitle: '',
            modalText: '',
            modalOkButton: false,
            modalCancelButton: false,
            suggestions: [],
            draggable: true,
            from: this.from
        }
    }

    updatePreviewBox = () => {
        let cRef = this.candidateListRef
        let pRef = this.previewBoxRef
        if (!cRef || !pRef) {
            return
        }

        let t = window.scrollY - cRef.getBoundingClientRect().x
        let d = pRef.getBoundingClientRect().height - 60
        pRef.style.visibility = t > d ? 'visible' : 'hidden'
        pRef.style.pointerEvents = t > d ? 'initial' : 'none'
    }

    elog = (code, value) => {
        this.hub.logStat(this.pageCode, code)
    }

    componentWillMount() {
        this.urlUnlisten = this.props.history.listen((location) => {
            const mode = location.pathname.split('/')[2]
            this.mode = mode
            this.pageCode = mode === 'upload' ? 'crowdsource/upload' : `crowdsource/digitize`
            this.setState({
                page: mode === 'details' ? 2 : 1,
                province: this.state.province,
                amphoe: this.state.amphoe,
                tambon: this.state.tambon,
                zone: this.state.zone,
                unit: this.state.unit,
                results: {},
                noVote: 0,
                total: 0,
                voided: 0,
                eligible: 0,
                files: [],
                selectedFile: null,
                showWebcam: false,
                reportId: '',
                modalVisible: false,
                modalTitle: '',
                modalText: '',
                modalOkButton: false,
                modalCancelButton: false,
                suggestions: []
            })
        })
    }
    componentWillUnmount() {
        if (this.urlUnlisten) this.urlUnlisten()

        this.clearResult()
        window.removeEventListener('scroll', this.updatePreviewBox)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode)

        let { id } = this.props.match.params
        const savedState = this.savedState
        let files = (savedState.files || [])

        let supportsPassive = false
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get: function () {
                    supportsPassive = true
                }
            })
            window.addEventListener("testPassive", null, opts);
            window.removeEventListener("testPassive", null, opts);
        } catch (e) {}

        window.addEventListener(
            'scroll',
            this.updatePreviewBox,
            supportsPassive ? { passive: true } : false
        )

        if (this.mode === 'details' && id) {
            this.loadDoc(id)
        } else {
            let fileFilter = files.filter(f => f.url)
            this.setState({
                province: savedState.province || this.state.province,
                amphoe: savedState.amphoe || this.state.amphoe,
                tambon: savedState.tambon || this.state.tambon,
                zone: savedState.zone || this.state.zone,
                unit: savedState.unit || this.state.unit,
                results: savedState.results || {},
                noVote: savedState.noVote || 0,
                total: savedState.total || 0,
                voided: savedState.voided || 0,
                files: fileFilter,
                selectedFile: files.length ? savedState.files[0] : null,
                prevId: false,
                nextId: false
            })
        }
    }

    getFriends = async(doc) => {

        if (!doc.crowdProvince || !doc.crowdZone || !this.state.from) {
            return;
        }

        let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
            .where('parentId', '==', '')
            .where('hasFiles', '==', true)
            .where('markAsAbuse', '==', false)
            .where('crowdProvince', '==', doc.crowdProvince)
            .where('crowdZone', '==', `${doc.crowdZone}`)
            .orderBy('crowdAmphoe', 'asc')
            .orderBy('crowdTambon', 'asc')
            .orderBy('crowdUnit', 'asc')
            .orderBy('created', 'asc')
            .get().then(qs => {
                let r = [];
                qs.forEach(item => { r.push({id:item.id, ...item.data()}); });
                return r;
            });

        let index = 0;
        results.forEach((r, i) => {
            if (r.id === doc.id) {
                index = i;
                return false;
            }
        });

        let prevId = results[index-1] ? results[index-1].id: false;
        let nextId = results[index+1] ? results[index+1].id: false;


        this.setState({
            prevId: prevId,
            nextId: nextId
        })


    }

    showModal = (title, text, options) => {
        let opts = {
            okButton: false,
            cancelButton: false,
            ok: () => this.hideModal(),
            ...options
        }
        this.setState({
            modalVisible: true,
            modalTitle: title,
            modalText: text,
            modalOkButton: opts.okButton,
            modalCancelButton: opts.cancelButton,
            modalOnOk: opts.ok
        })
    }
    hideModal = () => {
        this.setState({
            modalVisible: false
        })
    }

    // loadZoneData = async () => {
    //     const province = this.provinces.find(it => this.state.province === it.name)
    //     if (!province) {
    //         return
    //     }
    //     let res = await axios.get(`https://vote62api.opendream.in.th/api/zone?format=json&provinceId=${province.id}`)
    //     this.zoneData = res.data.items.find(it => `${it.no}` === this.state.zone)
    // }

    loadDoc = async (id) => {
        try {
            // this.showModal('กำลังดึงข้อมูล', '')
            this.setState({
                loading: true
            })

            let ref = this.hub.db.collection(this.getDBKey()).doc(id)
            let res = await ref.get()
            let doc = {id: res.id, ...res.data()}

            this.getFriends(doc);


            if (doc.markAsAbuse) {
                return this.showModal('ไม่มีรายงานนี้อยู่', 'กรุณากลับไปที่หน้าแรก', {
                    okButton: true,
                    ok: () => {
                        this.props.history.push(`/crowdsource/`)
                    }
                })
            }

            let savedState = this.savedState
            if (savedState.reportId !== id) {
                savedState = {...savedState, files: doc.files, province: '', amphoe: '', tambon: '', zone: '', unit: '', results: []}
            }

            let files = savedState.files || doc.files
            this.setState({
                page: 2,
                reportId: id,
                province: savedState.province || doc.province,
                amphoe: savedState.amphoe || doc.amphoe,
                tambon: savedState.tambon || doc.tambon,
                zone: savedState.zone || doc.zone,
                unit: savedState.unit || doc.unit,
                results: savedState.results || doc.results,
                noVote: savedState.noVote || doc.noVote,
                total: savedState.total || doc.total,
                voided: savedState.voided || doc.voided,
                files: files,
                selectedFile: files.length ? savedState.files[0] : null,
                doc: doc,
                loading: false
            })

            let province = ''
            let amphoe = ''
            let tambon = ''
            let unit = ''
            let selectedLocation = getUrlParameter('location')
            
            if (selectedLocation) {
                selectedLocation = selectedLocation.split('--')
                province = selectedLocation[0]
                amphoe = selectedLocation[1]
                tambon = selectedLocation[2]
                unit = selectedLocation[3]
                console.log('location', unit)
    
            }

            this.onProvinceChange({target: {value: province || savedState.province || doc.province}});
            this.onAmphoeChange({target: {value: amphoe || savedState.amphoe || doc.amphoe}});
            this.onTambonChange({target: {value: tambon || savedState.tambon || doc.tambon}});
            this.onZoneChange({target: {value: '' || savedState.zone || doc.zone}});
            this.onUnitChange({target: {value: unit || savedState.unit || doc.unit}});

            if (ref) {
                let totalVisited = doc.totalVisited || 0;
                totalVisited++;

                let callable = await this.hub.functions.httpsCallable('updateOrder')
                callable({
                    env: this.hub.appMode,
                    reportId: id,
                    totalVisited: totalVisited,
                })
            }

        } catch (e) {
            console.log(e)
        }
        // this.hideModal()
    }

    getProvinceSuggestions = ({value}) => {
        const v = value.trim().toLowerCase()
        return !v ? this.provinces : this.provinces.filter(p => p.name.toLowerCase().match(new RegExp(v)))
    }

    onProvinceSuggestionsFetchRequested = value => {
        this.setState({suggestions: this.getProvinceSuggestions(value)})
    }

    onProvinceSuggestionsClearRequested = () => {
        this.setState({suggestions: []})
    }

    onProvinceChange = (ev) => {
        let amphoes = [];
        if (ev.target.value) {
            amphoes = this.provinces.filter(province => province.name === ev.target.value)[0].children;
        }
        this.setState({
            province: ev.target.value,
            amphoes: amphoes,
            amphoe: '',
            tambons: [],
            tambon: '',
            units: [],
            unit: '',
        })
        // this.loadZoneData()
        // this.elog('choose-election-province', {province_name: ev.target.value})
    }

    onZoneChange = (ev) => {

        // let amphoes = this.state.zones.filter(zone => zone.name === ev.target.value)[0].children;
        

        // this.setState({
        //     zone: ev.target.value,
        //     amphoes: amphoes,
        //     tambons: [],
        //     units: [],
        // })

        this.setState({zone: ev.target.value})
    }

    onAmphoeChange = (ev) => {

        let tambons = [];
        if (ev.target.value) {
            tambons = this.state.amphoes.filter(amphoe => amphoe.name === ev.target.value)[0].children;
        }
        this.setState({
            amphoe: ev.target.value,
            tambons: tambons,
            tambon: '',
            units: [],
            unit: '',
        })
    }

    onTambonChange = (ev) => {

        let units = [];
        if (ev.target.value) {
            units = this.state.tambons.filter(tambon => tambon.name === ev.target.value)[0].children;
        }

        this.setState({
            tambon: ev.target.value,
            units: units
        })
    }

    onUnitChange = (ev) => {
        this.setState({unit: ev.target.value})
        // this.elog('choose-election-province-unit-subunit', {
        //     province_name: this.state.province,
        //     zone: this.state.zone,
        //     unit: ev.target.value
        // })
    }

    onChange = (ev) => {
        this.setState({province: ev.target.value})
        // this.loadZoneData()
        // this.elog('choose-election-province', {province_name: ev.target.value})
    }

    nextPage = async () => {
        await this.submit()

        const reportId = this.state.reportId
        this.props.history.push(`/crowdsource/digitize/${reportId}${window.location.search}`)
        this.clearResult()
        setTimeout(() => {
            this.loadDoc(reportId)
        }, 500)
    }

    prevPage = () => {
        this.setState({page: Math.max(this.state.page-1, 1)})
    }

    onFilesDrop = async (files, category) => {
        let items = await Promise.all(files.map(async f => {
            let url = await new Promise(resolve => {
                let reader = new FileReader()
                reader.addEventListener('load', () => {
                    resolve(reader.result)
                }, false)
                reader.readAsDataURL(f)
            })

            return Promise.resolve({
                id: uuidv4(),
                file: f,
                uploading: true,
                url: url,
                category: category,
            })
        }))
        this.setState({files: [...this.state.files, ...items]})

        items.map(async it => {
            const {files} = this.state
            let res = await this.upload(it)
            let idx = files.findIndex(f => f.id === res.id)
            if (idx > -1) {
                files[idx] = {...files[idx], ...res}
                this.setState({files: files})
            }
            return res
        })
    }

    upload = item => {
        return new Promise((resolve, reject) => {
            let file = item.file

            const reader = new window.FileReader()
            reader.addEventListener('load', () => {
                resizeImage(reader.result, file.name, file.type)
                    .then(async (result) => {
                        let resizedFile = file
                        if (result.resized) {
                            resizedFile = result.newFile
                        }

                        let storageRef = firebase.storage().ref()
                        let imgRef = storageRef.child(`uploads/vote-results/${item.id}`)
                        let snapshot = await imgRef.put(resizedFile)
                        item.url = await snapshot.ref.getDownloadURL()
                        item.uploading = false

                        resolve(item)
                    })
                    .catch(err => {
                        console.log(err)
                        item.uploading = false
                        reject(err)
                    })
            })
            reader.readAsDataURL(file)
        })
    }

    renderFileItem = (item, zoomable) => {
        let progressBar = ''
        if (item.uploading) {
            progressBar = (
                <div className="position-absolute w-100 h-100 d-flex align-items-center text-center" style={{left: 0, top: 0}}>
                    <div className="progress w-75 mx-auto">
                        <div className="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            )
        }

        return (
            <div key={item.id} className="position-relative h-100">
                {zoomable ?
                    <div className="zoom-wrapper">
                        <PinceZoomPan maxScale={3} position="center">
                            <img src={item.url} alt="" className="rounded w-100" style={{opacity: item.uploading ? 0.6 : 1}} />
                        </PinceZoomPan>
                    </div> :
                    <img src={item.url} alt=""
                         className="rounded w-100"
                         style={{opacity: item.uploading ? 0.6 : 1}} />
                }
                {progressBar}
            </div>
        )
    }

    removeFile = item => {
        const files = this.state.files
        let idx = files.findIndex(f => f.id === item.id)
        let newFiles = files.filter(f => f.id !== item.id)
        // let newSelectedFile = files[Math.min(idx, newFiles.length - 1)]

        this.setState({files: newFiles, selectedFile: null})
    }

    showWebcam = () => {
        this.setState({showWebcam: true})
    }

    closeWebcam = () => {
        this.setState({showWebcam: false})
    }

    takePhoto = async webcam => {
        if (!webcam) {
            return
        }

        const dataUrl = webcam.getScreenshot()
        const dataBlob = dataURItoBlob(dataUrl)

        this.closeWebcam()

        let item = {
            id: uuidv4(),
            file: dataBlob,
            uploading: false,
            url: dataUrl
        }
        let files = [...this.state.files, item]
        this.setState({files: files})

        const res = await this.upload(item)
        let idx = files.findIndex(f => f.id === res.id)
        files[idx] = {...files[idx], ...res}
        this.setState({files: files})
    }

    webcam = () => {
        let webcam
        const setRef = wc => {
            webcam = wc
        }

        const videoConstraints = {
            height: 1280,
            width: 720,
            facingMode: 'environment'
        }
        const webcamWrapperStyle = {
            position: 'fixed',
            top: 0, left: 0, bottom: 0, right: 0,
            backgroundColor: '#000',
            zIndex: 99999
        }
        const takePhoto = () => {
            this.takePhoto(webcam)
        }
        return (
            <div style={webcamWrapperStyle}>
                <div className="text-right">
                    <button className="btn btn-outline btn-close m-2" onClick={this.closeWebcam}>x ปิด</button>
                </div>
                <Webcam ref={setRef} className="mt-2" width="100%" audio={false} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />

                <div className="text-center fixed-bottom mb-3">
                    <button className="btn btn-lg" onClick={takePhoto}>
                        <i className="fa fa-camera"></i> ถ่ายรูป
                    </button>
                </div>
            </div>
        )
    }

    sectionTakeAPhoto = () => {
        return (
            <div>
                <span className="text-muted my-2">หรือ</span>
                <div className="p-1 d-flex align-items-center justify-content-center">
                    <button className="btn" onClick={this.showWebcam}>
                        <i className="fa fa-camera"></i> ถ่ายรูป
                    </button>
                </div>
            </div>
        )
    }

    previewFile = (item, idx) => {
        this.setState({selectedFile: item})
        this.sliderRef.slickGoTo(idx)
    }

    showPosition = (position) => {
        this.setState({
            currentLocation: {lat: position.coords.latitude, lng: position.coords.longitude},
            findingCurrentLocation: false,
        })

    }

    getLocation = () => {
        this.setState({findingCurrentLocation: true})
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.showPosition,
                () => {
                    this.showPosition({coords: {latitude: this.defaultLocation.lat, longitude: this.defaultLocation.lng}})},
                {enableHighAccuracy: true, timeout: 8000}
            );
        } else {

            this.setState({
                currentLocation: this.defaultLocation,
                findingCurrentLocation: false
            })
        }

    }

    onCircleInteraction = (childKey, childProps, mouse) => {
        // function is just a stub to test callbacks
        this.setState({
            draggable: false,
            currentLocation: {
                lat: mouse.lat,
                lng: mouse.lng
            }
        });
    }
    onCircleInteraction3 = (childKey, childProps, mouse) => {
        this.setState({draggable: true});
    }

    _onChange = ({center, zoom}) => {

        console.log(center, zoom)

        this.setState({
            currentLocation: center,
            zoom: zoom,
        });
    }

    renderFileUpload = (category) => {

        const { selectedFile, files } = this.state

        return (
            <div className="text-center pb-4">
                <div className="row mt-1 no-gutters">
                    {files.filter(item => item.category == category).map((item, idx) => {
                        return (
                            <div className={`col-3 p-1 mt-1 image-thumb-thick-white ${selectedFile && selectedFile.id === item.id ? 'active' : ''}`}
                                key={idx}
                                onClick={() => this.previewFile(item, idx)}>
                                {this.renderFileItem(item)}
                            </div>
                        )
                    })}
                </div>

                <div className="pt-3 w-lg-50 m-auto _font-db">
                    <DropZone onFilesDrop={(files) => this.onFilesDrop(files, category)} />
                </div>
            </div>
        )
    }

    page1 = () => {
        const { files, showWebcam, selectedFile } = this.state
        const containerStyle = showWebcam ? {overflow: 'hidden'} : {}

        let name = this.state.name
        let contact = this.state.contact

       let zoom = 17
        let center = this.defaultLocation

        if (this.state.currentLocation) {
            center = this.state.currentLocation
        }
        let googleMapApiKey = 'AIzaSyAObvO0WYdVKkhRqPBI3TPh3CMew9thmn0'


        const Marker = (props) => {
            const { color, name, id } = props;
            return (
                <div className="marker"
                    style={{ backgroundColor: color, cursor: 'pointer'}}
                    title={name}
                />
            );
        };

        const readyToClick = files.every(item => item.uploading == false)

        return (
            <div className="container-fluid pt-md-5 pt-3 -t pb-lg-5wrapper-height100" style={containerStyle}>
                {showWebcam ? this.webcam() : ''}
                <div className="text-center mb-4">
                    <h2 className="mb-0 _fw-bd">กรุณาอัปโหลดภาพ</h2>
                </div>

                {selectedFile ?
                <>
                    <div className={`modal-backdrop fade show`} onClick={() => this.setState({selectedFile: null})}>
                    </div>
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: `block`}} onClick={() => this.setState({selectedFile: null})}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content position-relative" onClick={e => {e.stopPropagation()}}>
                                {this.renderFileItem(selectedFile)}

                                <button className="btn btn-danger btn-sm position-absolute"
                                        onClick={() => this.removeFile(selectedFile)}
                                        style={{top: '8px', left: '8px'}}>ลบ</button>

                                <button className="text-white bg-black position-absolute"
                                        onClick={() => this.setState({selectedFile: null})}
                                        style={{top: '-14px', right: '-12px', width: 27, height: 27, borderRadius: `50%`, lineHeight: `20px`, border: `solid 2px white`}}>&times;</button>
                            </div>
                        </div>
                    </div>
                </>
                : ''}

                <div className="row justify-content-center mt-4">
                    <div className={`col-12 col-md-6 col-offset-md-3 text-center`}>
                        <h4>
                            <span className="badge badge-dark badge-pill">
                                รูปที่ 1 <a href="#" className={`text-white _fw-li _fs-16`} data-toggle="modal" data-target="#ex-unit"><u>ดูตัวอย่างภาพ</u></a>
                                <div className="modal fade" id="ex-unit" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title text-dark" style={{marginTop: '-5px', marginBottom: '5px'}}>ตัวอย่างภาพ ป้ายไวนิลหน้าหน่วยเลือกตั้ง</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img className={`img-fluid`} src={`/static/images/upload-ex-unit.jpg`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </h4>
                        <h4>ป้ายไวนิลหน้าหน่วยเลือกตั้ง</h4>
                        {this.renderFileUpload('unit')}
                    </div>
                </div>

                <div className="row justify-content-center mt-4">
                    <div className={`col-12 col-md-6 col-offset-md-3 text-center`}>
                        <h4>
                            <span className="badge badge-dark badge-pill">
                                รูปที่ 2 <a href="#" className={`text-white _fw-li _fs-16`} data-toggle="modal" data-target="#ex-board"><u>ดูตัวอย่างภาพ</u></a>
                                <div className="modal fade" id="ex-board" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title text-dark" style={{marginTop: '-5px', marginBottom: '5px'}}>ตัวอย่างภาพ กระดานคะแนนเลือกตั้ง</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img className={`img-fluid`} src={`/static/images/upload-ex-board.jpg`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </h4>
                        <h4>กระดานคะแนนเลือกตั้ง (จำเป็น)</h4>
                        <h5 className="_fw-reg">(เฉพาะหน่วยที่นับคะแนนเรียบร้อยแล้วเท่านั้น)</h5>
                        {this.renderFileUpload('board')}
                    </div>
                </div>

                <div className="row justify-content-center mt-4">
                    <div className={`col-12 col-md-6 col-offset-md-3 text-center`}>
                        <h4>
                            <span className="badge badge-dark badge-pill">
                                รูปที่ 3 <a href="#" className={`text-white _fw-li _fs-16`} data-toggle="modal" data-target="#ex-result"><u>ดูตัวอย่างภาพ</u></a>
                                <div className="modal fade" id="ex-result" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title text-dark" style={{marginTop: '-5px', marginBottom: '5px'}}>ตัวอย่างภาพ ใบรายงานผลการนับคะแนน</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img className={`img-fluid`} src={`/static/images/upload-ex-result.jpg`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </h4>
                        <h4>ใบรายงานผลการนับคะแนน</h4>
                        {this.renderFileUpload('result')}
                    </div>
                </div>

                <div className="row justify-content-center pb-4">
                    <div className="col-12 py-5 bg-light-grey d-none" style={{ backgroundImage: 'url(/static/images/bg-location.png)', backgroundPosition: 'center', backgroundSize: 'cover' }}>

                        <div className="row justify-content-md-center">
                            <div className="col-lg-5 _font-db text-white text-center">
                                <h3>สถานที่เลือกตั้ง</h3>
                                <p className="help-text mb-0">จะเป็นประโยชน์มาก หากคุณช่วยระบุที่อยู่ของหน่วยเลือกตั้งนี้ ทาง Vote62 จะนำไปจัดทำแผนที่หน่วยเลือกตั้งทั่วประเทศ</p>

                                <div className="my-3">
                                    <button className="btn btn-light _fs-24" onClick={() => this.getLocation()}>
                                        <img className="mr-2" src="/static/images/ic-map-marker.png" srcSet="/static/images/ic-map-marker.png 1x, /static/images/ic-map-marker@2x.png 2x" /> {this.state.findingCurrentLocation? 'กำลังค้นหา ...': 'ระบุตำแหน่งสถานที่เลือกตั้ง' }
                                    </button>
                                </div>

                                <p className="help-text">เมื่อกดปุ่มนี้ระบบจะหาตำแหน่งที่ตั้งปัจจุบันของคุณ หากมีหน้าต่างขออณุญาตกรุณาตเข้าถึงตำแหน่ง กรุณากดยอมรับ (Allow)</p>
                                {this.state.currentLocation ?
                                <>
                                    <p className="help-text">แก้ไขตำแหน่งหากไม่ถูกต้องโดยการเลื่อนแผนที่ได้</p>
                                    <div style={{ height: '300px', width: '100%', position: 'relative' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: googleMapApiKey }}
                                            options={{fullscreenControl:false}}
                                            // defaultCenter={defaultCenter}
                                            draggable={this.state.draggable}
                                            center={center}
                                            zoom={zoom}

                                            onChange={this._onChange}

                                            // onChildMouseDown={this.onCircleInteraction}
                                            // onChildMouseUp={this.onCircleInteraction3}
                                            // onChildMouseMove={this.onCircleInteraction}
                                        >

                                            <Marker
                                                lat={center.lat}
                                                lng={center.lng}
                                                draggable={true}
                                                name="ตำแหน่งของฉัน"
                                                color="blue"
                                            />

                                        </GoogleMapReact>

                                        <div className="mask-marker"></div>
                                    </div>
                                </>
                                : ``}
                            </div>
                        </div>

                    </div>


                    <div className="col-12 py-5 d-none">

                        <div className="row justify-content-md-center">
                            <div className="col-lg-5 _font-db text-center">
                                <h3>ข้อมูลสำหรับการติดต่อ</h3>
                                <p className="help-text">ทางทีมงานจะใช้ข้อมูลส่วนนี้ในการติดต่อคุณ ในกรณีที่อยากทราบรายละเอียดเพิ่มเติม หากไม่สะดวกให้เว้นว่างไว้</p>
                                <ul className="list-unstyled">
                                    <li className="d-flex justify-content-between align-items-center bg-light-grey-alt-1 py-2 px-3 mb-1">
                                        <div className="w-25 label text-white text-left">
                                            <div className="">ชื่อผู้ส่งภาพ</div>
                                            <div>Optional</div>
                                        </div>

                                        <div className="w-75">
                                            <input type="text"
                                                className="form-control small"
                                                defaultValue={name}
                                                onChange={(e) => this.setState({name: e.target.value})} />
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center bg-light-grey-alt-1 py-2 px-3">
                                        <div className="w-25 label text-white text-left">
                                            <div className="">เบอร์โทร/อีเมล</div>
                                            <div>Optional</div>
                                        </div>
                                        <div className="w-75">
                                            <input type="text"
                                                className="form-control small"
                                                defaultValue={contact}
                                                onChange={(e) => this.setState({contact: e.target.value})} />
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>


                </div>

                {(files.filter(item => item.category == 'board').length && files.filter(item => item.category == 'unit').length && files.filter(item => item.category == 'result').length)?
                <div>
                    <div className="text-center">
                        <div className="badge badge-dark badge-pill">
                            <a href="#" className={`text-white _fw-li _fs-16`} data-toggle="modal" data-target="#terms-conditions"><u>ข้อตกลงการใช้งาน</u></a>
                            <div className="modal fade" id="terms-conditions" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title text-dark _fs-24" style={{marginBottom: '5px'}}>ข้อตกลงการใช้งาน</h3>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="text-left text-dark _fw-reg _fs-20 mb-2" style={{whiteSpace: `normal`}}>ข้าพเจ้าขอรับรองว่าภาพและสื่ออื่นใดที่ข้าพเจ้านำเข้าสู่ระบบมาจากการบันทึกของข้าพเจ้าเอง โดยมิได้มีการเสริมแต่งใดในลักษณะที่จะทำให้สาระสำคัญในภาพหรือสื่อดังกล่าวปรากฏผิดไปจากข้อเท็จจริง ท้ังนี้เพื่อประโยชน์ในการนำภาพไปใช้งานต่อได้โดยสะดวก ซึ่งรวมถึงการตรวจสอบคะแนนและการเผยแพร่ทางสื่อสารมวลชน ข้าพเจ้าขอสละลิขสิทธิ์และสิทธิ์ที่เกี่ยวข้องข้างเคียงทุกประการในภาพและสื่ออื่นใดที่ข้าพเจ้าได้นำเข้าสู่ระบบ รวมถึงสละการเรียกร้องหรือเงื่อนไขใดๆ ตามที่กฎหมายจะอนุญาตให้ทำได้ โดยภาพและสื่อทั้งหมดจะตกเป็นสมบัติของสาธารณะที่ทุกคนสามารถทำสำเนา ดัดแปลง เผยแพร่ และจัดแสดงต่อได้ ตามคำอุทิศให้เป็นสมบัติของสาธารณะครีเอทีฟคอมมอนส์ CC0 1.0 Universal</p>
                                            <a className="_fw-reg _fs-20 text-left" href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank">https://creativecommons.org</a>
                                        </div>
                                        <div className="modal-footer text-center">
                                            <button className="btn btn-nc btn-dark" type="button" data-dismiss="modal" aria-label="Close">ตกลง</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            {readyToClick?
                            <button className={`btn btn-nc btn-lg btn-dark`} onClick={this.nextPage}>ส่งรูป</button>
                            :
                            <button className={`btn btn-nc btn-lg btn-dark disabled`} disabled>ส่งรูป</button>
                            }
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col small text-center text-muted">
                            <div onClick={this.clearResult} style={{textDecoration: 'underline'}}><i className="fas fa-sync-alt"></i> ล้างข้อมูล</div>
                        </div>
                    </div>
                </div>
                :
                <div className="row my-3">
                    <div className="col text-center">
                        <small className={`text-muted`}>
                            ปุ่มส่งรูปจะปรากฎหลังจากอัปโหลดภาพกระดานเลือกตั้งแล้ว
                        </small>
                    </div>
                </div>
                }
            </div>
        )
    }

    setZeroInput = ({target}) => {
        const value = parseInt(target.value, 10) || 0
        if (value === 0) {
            target.value = ''
        }
    }
    setZeroValue = ({target}) => {
        const value = target.value
        if (!value.trim()) {
            target.value = '0'
        }
    }

    renderResultRow = (candidate) => {
        const updateResult = ({target}) => {
            const value = parseInt(target.value, 10) || 0
            // setTimeout(() => this.elog('fill-candidate-result', {
            //     province_name: this.state.province,
            //     zone: this.state.zone,
            //     unit: this.state.unit,
            //     candidate_no: candidate.no
            // }), 0)
            this.setState({results: {...this.state.results, [candidate.no]: value}})
        }

        const defaultValue = this.state.results[candidate.no] || 0

        return (
            <div className="row mb-1 pb-1" key={candidate.no}>
                <div className="col">
                    <div className="d-flex card-black justify-content-center align-items-center">
                        <div className="candidate-item-left mr-3">
                            <h4>เบอร์</h4>
                            <h2>{candidate.no}</h2>
                        </div>
                        <div className="candidate-item-body">
                            <h3 className="candidate_title">{candidate.name}</h3>
                            {/* <h5 className="candidate_name">{candidate.party}</h5> */}
                        </div>
                        <div className="ml-auto col-3 px-0">
                            <input type="number" min="0"
                                   className="form-control text-right small"
                                   onWheel={(ev) => ev.target.blur()}
                                   value={defaultValue}
                                //    defaultValue={defaultValue}
                                   onClick={this.setZeroInput}
                                   onBlur={this.setZeroValue}
                                   onChange={updateResult} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    updateVoided = ({target}) => {
        const v = parseInt(target.value, 10) || 0
        // setTimeout(() => this.elog('fill-failed-vote-result', {
        //     province_name: this.state.province,
        //     zone: this.state.zone,
        //     unit: this.state.unit,
        //     total: v
        // }), 0)
        this.setState({voided: v})
    }

    updateNoVote = ({target}) => {
        const v = parseInt(target.value, 10) || 0
        // setTimeout(() => this.elog('fill-novote-result', {
        //     province_name: this.state.province,
        //     zone: this.state.zone,
        //     unit: this.state.unit,
        //     total: v
        // }), 0)
        this.setState({noVote: v})
    }

    updateEligible = ({target}) => {
        const v = parseInt(target.value, 10) || 0
        // setTimeout(() => this.elog('fill-numberofvoter-result', {
        //     province_name: this.state.province,
        //     zone: this.state.zone,
        //     unit: this.state.unit,
        //     total: v
        // }), 0)
        this.setState({eligible: v})
    }

    reportAbuse = () => {

        this.showModal('คุณต้องการแจ้งว่ารายงานนี้ไม่เหมาะสมหรือไม่', '', {
            okButton: true,
            cancelButton: true,
            ok: async () => {
                this.setState({
                    modalLoading: true
                })
                try {

                    const url = this.hub.config.useEmulator ?
                        `${this.hub.config.emulatorFunctionsUrl}/thai-vote-pao-62/us-central1` :
                        `https://us-central1-${this.hub.config.projectId}.cloudfunctions.net`

                    const env = this.hub.appMode

                    await axios.post(`${url}/reportAbuse?env=${env}`, {reportId: this.state.reportId})
                    this.showModal('ขอบคุณที่แจ้งปัญหานี้', '', {
                        okButton: true,
                        ok: () => {
                            this.hideModal()
                            this.props.history.push(`/crowdsource/`)
                        }

                    })
                    // this.elog('submit-report-improper-image', {
                    //     province_name: this.state.province,
                    //     amphoe: this.state.amphoe,
                    //     tambon: this.state.tambon,
                    //     zone: this.state.zone,
                    //     unit: this.state.unit
                    // })

                } catch (e) {
                    console.error(e)
                }
                this.setState({
                    modalLoading: false
                })

            }
        })

        // this.elog('click-report-improper-image', {
        //     province_name: this.state.province,
        //     amphoe: this.state.amphoe,
        //     tambon: this.state.tambon,
        //     zone: this.state.zone,
        //     unit: this.state.unit
        // })
    }

    reportAbnormal = () => {
        this.reportAbnormalModal.current.show()
    }

    onReportAbnormalOK = () => {
        this.showModal('กำลังบันทึก', 'กรุณารอสักครู่...')
    }
    onReportAbnormalSaved = () => {
        this.reportAbnormalModal.current.hide()
        this.showModal('บันทึกแล้ว', 'ขอบคุณที่รายงาน', {
            okButton: true,
            ok: () => {
                this.hideModal()
            }
        })
    }
    onReportAbnormalError = e => {
        this.showModal('ผิดพลาด', 'กรุณาลองใหม่อีกครั้ง', {okButton: true})
    }

    renewResult = async () => {
        console.log('renewResult', this.state.reportId)
        const c = this.getDBKey()

        let originDoc = await this.hub.db.collection(c).doc(this.state.reportId).get()
        if (originDoc) {
            let originData = originDoc.data();
            let totalRenews = originData.totalRenews || 0;
            totalRenews++

            let callable = await this.hub.functions.httpsCallable('updateOrder')
            callable({
                env: this.hub.appMode,
                reportId: this.state.reportId,
                totalRenews: totalRenews,
            })

        }
    }



    page2 = () => {
        const { province, amphoe, tambon, zone, unit, files, selectedFile } = this.state

        if (province) {
            // this.loadZoneData()
        }

        const sliderSettings = {
            dots: false,
            infinite: false,
            adaptiveHeight: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false
        }

        const sliderSettingsPager = {
            customPaging: (i) => {
                return (
                    <a>
                        {this.renderFileItem(files[i], false)}
                    </a>
                );
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: false,
            adaptiveHeight: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false
        }


        let previewBox
        const setPreviewBox = (ref) => {
            if (!ref) return
            previewBox = ref
            this.previewBoxRef = ref
        }

        const setRef = (ref) => {
            if (!ref) {
                return
            }
            this.candidateListRef = ref
        }
        const setSliderRef = (ref) => {
            if (!ref) return;
            this.sliderRef = ref
        }

        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        
        const updateScores = (scores) => {
            console.log('update', scores);
            scores.forEach(item => {
                console.log('setstate', item.number, item.score);
                if (item.number === 'all') {
                    this.setState({
                        eligible: item.score
                    })
                } else if (item.number === -1) {
                    this.setState({
                        voided: item.score
                    })
                } else if (item.number === 0) {
                    this.setState({
                        noVote: item.score
                    })
                } else {
                    this.setState({results: {...this.state.results, [item.number]: item.score}}, () => {console.log('update statre', this.state.results)})
                }
            });
        };

        return (
            <div className="container">
                {this.state.loading?
                    <div>
                        {loader}
                    </div>
                    :
                    <div className="_mb-90">
                        <div className="d-flex flex-row align-items-start my-3">
                            <Link to={this.from? this.from: `/crowdsource`} className="btn btn-light d-print-none lh0 mr-2">
                                <i className="fa fa-chevron-left fa-2"></i>
                            </Link>

                            <a href="#" data-toggle="modal" data-target="#statistic-modal" className={`btn btn-light d-print-none ml-2 lh0 ${this.state.from? 'd-none': ''}`}>
                                <i className="fa fa-bar-chart fa-2"></i>
                            </a>
                            <Link to="/crowdsource/digitize" onClick={this.renewResult} className={`btn _fw-reg btn-light d-print-none ml-2 lh0 ${this.state.from
? 'd-none': ''}`}>
                                <i className="fa fa-random"></i> สุ่มใหม่
                            </Link>
                            {(window.location.search.indexOf('open') !== -1)?
                            <button className={`ml-2 _fw-reg btn btn-light d-print-none lh0 ${this.state.from? 'ml-auto d-none': ''}`} onClick={this.reportAbuse}>
                                <i className="fa fa-exclamation-circle mr-1"></i>
                                แจ้งรูปไม่เหมาะสม
                            </button>
                            : ``}

                            <div className={`digitize-meta ${!this.state.from || this.fromLocation.length < 4? 'd-none': ''}`}>
                                <h3 className={`_fw-med _fs-36 _lh-80 mb-0`}>
                                    {this.fromLocation[3] || '-'} 
                                </h3>
                                {this.fromLocation[4]?
                                <div className={`_fw-med _fs-18`}>
                                    <div>เขต {this.fromLocation[4] || '-'}</div>
                                    {this.hub.utils.getDeepLocation(this.fromLocation[3] || null, this.fromLocation[4] || null)}
                                </div>
                                : ``}
                            </div>
                        </div>
                        <div className="position-fixed d-sm-none" ref={setPreviewBox}
                             style={{visibility: 'hidden', top: 0, left: 0, right: 0, height: '260px', zIndex: 99}}>
                            <Slider {...sliderSettings} ref={setSliderRef}>
                                {files.map(item => this.renderFileItem(item, true))}
                            </Slider>
                            
                            <div className="mobile">
                                <Speech onResult={updateScores}/>
                            </div>

                        </div>

                        <div className="row pb-4">
                            <div className="col text-center">
                                {selectedFile?
                                    <div className="row">
                                        <div className="col">
                                            {this.renderFileItem(selectedFile || files[0])}
                                        </div>
                                    </div> : ''
                                }
                                <div className="row mt-1 no-gutters">
                                    {files.map((item, idx) => {
                                        return (
                                            <div className={`col-3 mt-1 image-thumb-thick-white ${selectedFile && selectedFile.id === item.id ? 'active' : ''}`}
                                                 key={idx}
                                                 onClick={() => this.previewFile(item, idx)}>
                                                {this.renderFileItem(item)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col text-center">
                                <button
                                    className={
                                        ['_fw-reg btn btn-light d-print-none d-flex flex-row align-items-center mx-auto _fw-med _fs-16 _shadow-1dp',
                                        {'d-none': this.state.from}]}
                                    onClick={this.reportAbnormal}>
                                    <i className="fa fa-exclamation-circle _fs-28"></i>
                                    <span className="ml-2">พบสิ่งผิดปกติในภาพนี้</span>
                                </button>
                            </div>
                        </div>

                        <div className={`crowd-input-wrapper ${this.state.from? 'd-none': ''}`}>
                            <div className="row justify-content-md-center pt-2">
                                <div className="col-12 col-lg-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="row pt-2">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>เขตเลือกตั้งที่</label>
                                                        {/* <small class="form-text -help-text">* ถ้าไม่ทราบให้เว้นว่างไว้</small> */}
                                                        {/* <small className={`form-text -help-text d-none d-sm-block _opa-0`}>&nbsp;</small> */}
                                                        {/* <select value={zone} className="form-control" onChange={this.onZoneChange}>
                                                            <option value="">- เลือกเขต -</option>
                                                            {this.getZones(province).map(it => {
                                                                return (
                                                                    <option key={it} value={it}>{it}</option>
                                                                )
                                                            })}
                                                        </select> */}
                                                        <input type="number" min="1"
                                                            className="form-control"
                                                            defaultValue={zone}
                                                            onWheel={(ev) => ev.target.blur()}
                                                            onChange={this.onZoneChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row pt-2">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>จังหวัด</label>
                                                        <select value={province} className="form-control" onChange={this.onProvinceChange}>
                                                            <option value="" className="text-muted">- เลือกจังหวัด -</option>
                                                            {this.provinces.map(p => {
                                                                return (
                                                                    <option key={p.id} value={p.name}>{p.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-md-center">
                                <div className="col-12 col-lg-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="row pt-2">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>อำเภอ</label>
                                                        <select value={amphoe} className="form-control" onChange={this.onAmphoeChange}>
                                                            <option value="">- เลือกอำเภอ -</option>
                                                            {this.state.amphoes.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row pt-2">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>ตำบล</label>
                                                        <select value={tambon} className="form-control" onChange={this.onTambonChange}>
                                                            <option value="">- เลือกตำบล -</option>
                                                            {this.state.tambons.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-md-center mt-2">
                                <div className="col-12 col-lg-8">
                                    <div className="form-group">
                                        <label>สถานที่ / หน่วยเลือกตั้ง</label>
                                        {/* <small class="form-text -help-text">&nbsp;</small> */}
                                        <select value={unit} className="form-control" onChange={this.onUnitChange}>
                                            <option value="">- เลือกสถานที่ / หน่วยเลือกตั้ง -</option>
                                            {this.state.units.map(it => {
                                                return (
                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                )
                                            })}
                                            <optgroup label=""></optgroup>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div ref={setRef}>
                                {this.candidateList()}
                            </div>

                        </div>

                        <div className={`crowd-action-wrapper crowd-action-wrapper mx-auto text-center ${!this.state.from? 'd-none': ''}`}>
                            <div className={`d-flex flex-row align-items-center justify-content-center`}>
                                <button className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1 d-flex flex-row align-items-center justify-content-center`} onClick={() => {this.setState({from: ''})}}>
                                    <img src="/static/images/ic-send-result.png" srcSet="/static/images/ic-send-result.png 1x, /static/images/ic-send-result@2x.png 2x" width="30px" />
                                    <span className="ml-2">กรอกคะแนน</span>
                                </button>
                                <Link className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1 d-flex flex-row align-items-center justify-content-center`} to={`/crowdsource/upload`}>
                                    <img src="/static/images/ic-report.png" srcSet="/static/images/ic-report.png 1x, /static/images/ic-report@2x.png 2x" width="30px" />
                                    <span className="ml-2">อัพโหลดภาพ</span>
                                </Link>
                            </div>
                        </div>

                        {this.state.prevId || this.state.nextId ?
                        <div className={`crowd-nav-footer position-fixed bg-efefef d-flex flex-row align-items-center`}>
                            <div className="container-fluid">
                            {/* <div className="container"> */}
                                {this.state.prevId? 
                                    <Link className={`_link-no-dec _pull-left d-flex flex-row align-items-center`} to={`/crowdsource/digitize/${this.state.prevId}?from=${this.from}`}>
                                        <i className={`fa fa-chevron-left mr-2`}></i>
                                        <span className="_fw-med">อัลบัมก่อนหน้า</span>
                                    </Link>: ''
                                }
                                {this.state.nextId? 
                                    <Link className={`_link-no-dec _pull-right d-flex flex-row align-items-center`} to={`/crowdsource/digitize/${this.state.nextId}?from=${this.from}`}>
                                        <span className="_fw-med">อัลบัมถัดไป</span>
                                        <i className={`fa fa-chevron-right ml-2`}></i>
                                    </Link>: ''
                                }
                            </div>
                        </div>
                        :''}
                    </div>
                }
            </div>
        )
    }

    getZones = (province) => {
        const zones = this.hub.utils.zoneDetails
        const pItem = zones.find(z => z[1] === province)
        if (!province || !pItem) {
            return []
        }
        return new Array(pItem[2].length).fill('').map((it, idx) => idx + 1)
    }

    getAvailableCandidates = () => {
        const { province, zone } = this.state

        let terminatedParties = {}
        this.hub.terminates.forEach(t => terminatedParties[t.party] = true)

        return this.hub.candidates.filter(c => {
            return c.province === province && !terminatedParties[c.party]
            // return c.province === province && parseInt(c.zone) === parseInt(zone) && !terminatedParties[c.party]
        })
    }

    candidateList = () => {
        const { noVote, voided, eligible, province, amphoe, tambon, zone, unit } = this.state
        const candidates = this.getAvailableCandidates()
        const formValid = province.trim() && amphoe.trim() && tambon.trim() && zone.trim() && unit.trim()
        // const formValid = province.trim() && zone.trim()
        return (
            <div>

                <div className="row py-3 justify-content-md-center">
                    <div className="col-12">
                        {/* <h3 className="text-center mb-0">กรอกตัวเลขที่เห็นตามรูปภาพ</h3> */}
                        {formValid ?
                            candidates.map(c => this.renderResultRow(c)) :
                            <div className="table-danger rounded d-flex align-items-center justify-content-center _font-db">
                            {/* <div className="table-danger rounded d-flex align-items-center justify-content-center _db-helvethaicaX"> */}
                                <div className="p-2">กรุณากรอกฟอร์มด้านบนให้ครบก่อน ถึงจะกรอกคะแนนผู้สมัครได้</div>
                            </div>
                        }
                    </div>
                </div>

                {formValid ?
                <div className="row pt-3 justify-content-md-center">
                    <div className="col-12">
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <div className="w-75 label">ไม่เลือกผู้สมัครใด</div>
                                <div className="w-25"><input type="number"min="0"
                                                             className="form-control text-right small"
                                                             value={noVote}
                                                            //  defaultValue={noVote}
                                                             onWheel={(ev) => ev.target.blur()}
                                                             onClick={this.setZeroInput}
                                                             onChange={this.updateNoVote} />
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <div className="w-75 label">
                                บัตรเสีย
                                </div>
                                <div className="w-25"><input type="number"min="0"
                                                className="form-control text-right small"
                                                value={voided}
                                                // defaultValue={voided}
                                                onWheel={(ev) => ev.target.blur()}
                                                onClick={this.setZeroInput}
                                                onChange={this.updateVoided} /></div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <div className="w-75 label">ผู้มาใช้สิทธิ์ทั้งหมด</div>
                                <div className="w-25"><input type="number"min="0"
                                                className="form-control text-right small"
                                                value={eligible}
                                                // defaultValue={eligible}
                                                onWheel={(ev) => ev.target.blur()}
                                                onClick={this.setZeroInput}
                                                onChange={this.updateEligible} /></div>
                            </li>
                        </ul>
                    </div>
                </div> : '' }

                {formValid ?
                <div className="row my-3 my-lg-5">
                    <div className="col text-center">
                        <button className="btn btn-lg btn-nc btn-dark" onClick={this.save}>ส่งข้อมูล</button>
                    </div>
                </div> : ''}
            </div>
        )
    }

    render = () => {
        let { modalTitle, modalText, modalOkButton, modalCancelButton, modalOnOk, modalLoading } = this.state
        let tdStyle = {
            textAlign: 'right'
        }

        let startInputDateTime = this.hub.setting.startInputDateTime

        return (
            <div>
                {/*<canvas id="canvas-box"></canvas>*/}
                {/*<img src="/static/images/dummy.jpeg" onLoad={this.hotfixWithDummyImage} style={{display: 'none'}} />*/}
                <Modal
                    width="80%"
                    visible={this.state.modalVisible}
                    effect="fadeInUp"
                    onClickAway={this.hideModal}>
                    <div className="text-center _font-db">
                        <div className="p-4">
                            <h3>{modalTitle}</h3>
                            <div>{modalText}</div>

                            {modalLoading?
                                <div>
                                    <img src="/static/images/loading2.gif" alt="loading ..." />
                                </div> : ''
                            }

                            <div className="text-center mt-3">
                                {modalOkButton ? <button className="btn btn-danger _fs-2" onClick={modalOnOk} disabled={modalLoading}>ตกลง</button> : ''}
                                {modalCancelButton ? <button className="btn ml-2 btn-secondary _fs-20" onClick={this.hideModal} disabled={modalLoading}>ยกเลิก</button> : ''}
                            </div>
                        </div>
                    </div>
                </Modal>

                <ReportAbnormalModal ref={this.reportAbnormalModal}
                                     hub={this.hub}
                                     reportId={this.state.reportId}
                                     onOK={this.onReportAbnormalOK}
                                     onSaved={this.onReportAbnormalSaved}
                                     onError={this.onReportAbnormalError} />

                <div className="modal fade" id="statistic-modal" aria-labelledby="statisticModalLabel" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">ข้อมูลการประมวลผลรูปนี้ที่ผ่านมา</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {this.state.doc?
                                <div className="modal-body">
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th scope="row">เคยมีคนส่งข้อมูลแล้ว</th>
                                                <td style={tdStyle}>{this.state.doc.totalDigitizes}</td>
                                                <td>ครั้ง</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">เคยมีคนกดสุ่มใหม่</th>
                                                <td style={tdStyle}>{this.state.doc.totalRenews}</td>
                                                <td>ครั้ง</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">พรรคที่มีคะแนนโดยนับรวมทุกครั้งที่มีการส่งข้อมูลมา</th>
                                                <td style={tdStyle}>{this.state.doc.totalRealPartyScoreExists}</td>
                                                <td>พรรค</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">เคยมีคนเยี่ยมชมหน้านี้</th>
                                                <td style={tdStyle}>{this.state.doc.totalVisited}</td>
                                                <td>ครั้ง</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>

                <div className={!this.hub.setting.isInputOpen? `comming-soon-backdrop`: ``}>
                    {this.state.page === 1 ? this.page1() : this.page2()}
                </div>

                <CrowdSourcingOpenMask isInputOpen={this.hub.setting.isInputOpen} startInputDateTime={startInputDateTime} />

                {/* {this.state.page === 1 ? this.page1() : this.page2()} */}
            </div>
        )
    }

    getResult = () => {
        const files = [...this.state.files.filter(f => f.url.match(/^http/)).map(f => ({id: f.id, url: f.url}))]
        const state = this.state

        return {
            reportId: state.reportId,
            province: state.province,
            amphoe: state.amphoe || '',
            tambon: state.tambon || '',
            zone: state.zone,
            unit: state.unit,
            results: state.results,
            noVote: state.noVote,
            total: state.total,
            voided: state.voided,
            eligible: state.eligible,
            files: files,
            totalFiles: files.length,
            hasFiles: files.length > 0,
            name: state.name,
            contact: state.contact,
            lat: state.currentLocation && state.currentLocation.lat,
            lng: state.currentLocation && state.currentLocation.lng,
            created: new Date()
        }
    }

    clearResult = () => {
        this.setState({
            selectedFile: null,
            province: '',
            amphoe: '',
            tambon: '',
            zone: '',
            unit: '',
            results: [],
            noVote: 0,
            total: 0,
            voided: 0,
            currentLocation: null,
            files: []
        })
        window.localStorage.removeItem('voteResult')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.localStorage.setItem('voteResult', JSON.stringify(this.getResult()))
    }

    getDBKey = () => {
        return this.hub.appMode + '--' + 'vote-results'
    }

    save = async () => {
        const c = this.getDBKey()
        const data = {...this.getResult(), source: 'people', parentId: this.state.reportId, files: [], totalFiles: 0, hasFiles: false}

        let total = Object.keys(data.results).reduce((a, b) => a + data.results[b], 0)

        if (total >= 300000) {
            this.showModal(
                `กรุณาตรวจสอบตัวเลขอีกครั้ง`,
                `จำนวนรวมไม่ควรเกิิน ${numberWithCommas(total)}`,
                {
                    okButton: true
                }
            )

            // this.elog('failed-submit-result', {
            //     province_name: this.state.province,
            //     amphoe: this.state.amphoe,
            //     tambon: this.state.tambon,
            //     zone: this.state.zone,
            //     unit: this.state.unit
            // })

            return
        }

        try {
            this.showModal('กำลังส่งข้อมูล', 'กรุณารอสักครู่ ...')
            await this.hub.db.collection(c).add(data)

            if (data.parentId) {
                //let aggData = await this.hub.db.collection(c).where('parentId', '==', data.parentId).where('amphoe', '>', '').where('tambon', '>', '').get().then(qs => {
                let aggData = await this.hub.db.collection(c).where('parentId', '==', data.parentId).get().then(qs => {

                    let totalPartyScoreExistsMap = {}
                    qs.forEach(doc => {
                        let data = doc.data();

                        if (data.results) {
                            Object.keys(data.results).forEach(no => {
                                if (parseInt(data.results[no]) > 0) {
                                    totalPartyScoreExistsMap[no] = true;
                                }
                            })
                        }
                    })

                    let totalPartyScoreExists = Object.keys(totalPartyScoreExistsMap).length

                    return {
                        totalDigitizes: qs.size,
                        totalRealPartyScoreExists: totalPartyScoreExists,
                        totalPartyScoreExists: Math.min(5, totalPartyScoreExists)
                    }
                })

                let callable = await this.hub.functions.httpsCallable('updateOrder')
                let updateOrderData = {
                    env: this.hub.appMode,
                    reportId: data.parentId,
                    totalDigitizes: aggData.totalDigitizes,
                    totalRealPartyScoreExists: aggData.totalRealPartyScoreExists,
                    totalPartyScoreExists: aggData.totalPartyScoreExists
                }
                callable(updateOrderData)
            }
            this.clearResult()
            this.hideModal()

            // this.elog('submit-result', {
            //     province_name: this.state.province,
            //     amphoe: this.state.amphoe,
            //     tambon: this.state.tambon,
            //     zone: this.state.zone,
            //     unit: this.state.unit
            // })

            this.props.history.push(`/crowdsource/thank-you`)
        } catch (e) {
            console.log(e)

            // this.elog('failed-submit-result', {
            //     province_name: this.state.province,
            //     amphoe: this.state.amphoe,
            //     tambon: this.state.tambon,
            //     zone: this.state.zone,
            //     unit: this.state.unit
            // })
        }
    }

    submit = async () => {
        const c = this.getDBKey()
        this.showModal('กำลังส่งข้อมูล', 'กรุณารอสักครู่ ...')
        const state = this.state
        
        try {
            let data = {
                ...this.getResult(), 
                source: 'people', totalDigitizes: 0, totalPartyScoreExists: 0, totalRealPartyScoreExists: 0, totalRenews: 0, totalVisited: 0, markAsAbuse: false, 
                parentId: "",
                crowdProvince: state.province || '',
                crowdZone: state.zone || '',
                crowdAmphoe: state.amphoe || '',
                crowdTambon: state.tambon || '',
                crowdUnit: state.unit || '',
            }
            // this.elog('submit-result-image', {total_images: data.files.length})

            window.localStorage.setItem('name', data.name)
            window.localStorage.setItem('contact', data.contact)

            let doc = await this.hub.db.collection(c).add(data)


            this.setState({
                reportId: doc.id,
                page: 1,
                province: '',
                amphoe: '',
                tambon: '',
                zone: '',
                unit: '',
                results: [],
                noVote: 0,
                voided: 0,
                files: []
            })
            this.hideModal()

            // this.elog('completed-submit-result-image')
        } catch (e) {
            console.log(e)
            // this.elog('failed-submit-result-image')
        }
    }
}
import React from 'react'
import axios from 'axios'

export default class ReportAbnormalModal extends React.Component {
    constructor(props) {
        super(props)

        this.hub = props.hub
        this.state = {
            reason: 'WRONG_RESULT',
            details: '',
            email: '',

            invalidReason: false,
            invalidDetails: false
        }

        this.modalRef = React.createRef()
    }

    save = async () => {
        const {reason, details} = this.state

        let isValid = true
        if (!reason) {
            this.setState({
                invalidReason: true
            })
            isValid = false
        }
        if (!details) {
            this.setState({
                invalidDetails: true
            })
            isValid = false
        }
        if (!isValid) {
            return
        }
        this.setState({
            invalidReason: false,
            invalidDetails: false
        })

        if (this.props.onOK) {
            this.props.onOK()
        }

        const url = this.hub.config.useEmulator ?
            `${this.hub.config.emulatorFunctionsUrl}/thai-vote-pao-62/us-central1` :
            `https://us-central1-${this.hub.config.projectId}.cloudfunctions.net`

        const env = this.hub.appMode

        try {
            const report = {...this.state}
            await axios.post(`${url}/reportAbnormal/${this.props.reportId}?env=${env}`, report)
            if (this.props.onSaved) {
                this.props.onSaved()
            }
        } catch (e) {
            if (this.props.onError) {
                this.props.onError(e)
            }
        }
    }

    bootstrapModal = () => {
        const {reason, details, email, invalidReason, invalidDetails} = this.state

        return (
            <div className="modal fade" role="dialog" ref={this.modalRef}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">สิ่งผิดปกติในภาพนี้</h5>
                            <button type="button" className="close text-black" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <select className="form-control _fw-med _fs-22"
                                    required
                                    defaultValue={reason} onChange={e => this.setState({reason: e.target.value})}>
                                <option value="WRONG_RESULT">นับคะแนนผิด</option>
                                <option value="INCOMPLETE_IMAGES">ภาพไม่สมบูรณ์ (เช่น ไม่กรอกจำนวนผู้มาใช้สิทธิ์, เลขหน่วย)</option>
                                <option value="OTHER">อื่นๆ</option>
                            </select>
                            {invalidReason ?
                                <div className="form-text -help-text text-danger">
                                    กรุณาระบุความผิิดปกติของรายงานนี้
                                </div> : ''
                            }

                            <textarea rows="4"
                                className="mt-2 form-control border rounded _fw-med _fs-22"
                                placeholder="รายละเอียด"
                                required
                                defaultValue={details} onChange={e => this.setState({details: e.target.value})} />
                            {invalidDetails ?
                                <div className="form-text -help-text text-danger">
                                    กรุณาระบุรายละเอียด
                                </div> : ''
                            }

                            <input className="form-control mt-2 rounded _fw-med _fs-22"
                                   type="email"
                                   placeholder="อีเมล (optional)"
                                   defaultValue={email} onChange={e => this.setState({email: e.target.value})}
                            />
                        </div>
                        <div className="modal-footer d-flex flex-row justify-content-between">
                            <button type="button" className="btn btn-light _fs-20 _fw-med" data-dismiss="modal">ยกเลิก</button>
                            <button type="button" className="btn bg-black text-white _fs-20 _fw-med" onClick={this.save}>ส่งข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    show = () => {
        jQuery(this.modalRef.current).modal('show')
    }

    hide = () => {
        this.setState({
            reason: 'WRONG_RESULT',
            details: '',
            email: '',
            invalidReason: false,
            invalidDetails: false
        })
        jQuery(this.modalRef.current).modal('hide')
    }

    render() {
        return (
            <div className="report-abnormal-modal">
                {this.bootstrapModal()}
            </div>
        )
    }
}
import {Link} from "react-router-dom";
import React, {Component} from "react";

export default class Header extends Component {
    render() {

        const onGoingReport = false ;

        return (
            <div className="header position-relative" style={{ zIndex: 999 }}>
                <nav className="py-1 py-lg-2">
                    <img src="/static/images/black-dot.png" alt="" className="image-bg-black"/>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg justify-content-between">
                            <Link to="/" className="navbar-brand d-flex flex-row align-items-center mx-auto _mx-0-xs">
                                <img src="/static/images/logo-vote62-phase-2.png" srcSet="/static/images/logo-vote62-phase-2.png 1x, /static/images/logo-vote62-phase-2@2x.png 2x"
                                     alt="Vote 62" className="mr-1" />
                                <span className="_fw-bd _fs-14 _lh-80">เปิดผลคะแนนและร่วมตรวจสอบผลการเลือกตั้ง<br />นายกองค์การบริหารส่วนจังหวัด (อบจ.)</span>
                            </Link>

                            <div className="btn-topbar d-flex justify-content-center py-2 py-sm-0 mx-auto _mx-0-xs">
                                <Link to={`/crowdsource/result/${window.localStorage.getItem('province') || ``}`} className="nav-menu -upload -raw rounded px-2 pt-2 pb-0 _p-0-xs d-flex flex-column align-items-center">
                                <img className="mb-1" src="/static/images/ic-calculator.png" srcSet="/static/images/ic-calculator.png 1x, /static/images/ic-calculator@2x.png 2x" width="20px" /><span>ข้อมูลดิบ</span></Link>
                                <Link to="/crowdsource/upload" className="nav-menu -upload rounded px-2 pt-2 pb-0 _p-0-xs d-flex flex-column align-items-center">
                                <img src="/static/images/ic-report.png" srcSet="/static/images/ic-report.png 1x, /static/images/ic-report@2x.png 2x" width="30px" /><span>อัปโหลดภาพ</span></Link>
                                <Link to="/crowdsource/digitize" className="nav-menu -send rounded px-2 pt-2 pb-0 _p-0-xs d-flex flex-column align-items-center">
                                <img src="/static/images/ic-send-result.png" srcSet="/static/images/ic-send-result.png 1x, /static/images/ic-send-result@2x.png 2x" width="30px" /><span>กรอกคะแนน</span></Link>
                            </div>
                        </nav>
                    </div>
                </nav>
            </div>
        )
    }
}

import React from "react";


export default class CrowdSourcingOpenMask extends React.Component {

    render() {
        const {isInputOpen, startInputDateTime} = this.props;
        let months = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

        return (
          <>
            {/* <div className={!isInputOpen? `comming-soon-backdrop`: ``}>
                {this.state.page === 1 ? this.page1() : this.page2()}
            </div> */}


            {!isInputOpen?
            <div className="comming-soon position-fixed d-flex align-items-center justify-content-center flex-column">
                <h2 className="_fw-sbd text-uppercase">เปิดให้รายงานผลคะแนนหลังปิดหีบเลือกตั้ง</h2>
                <div>วันที่ {startInputDateTime.getDate()} {months[startInputDateTime.getMonth()]} {startInputDateTime.getFullYear() + 543} เวลา {startInputDateTime.getHours()}:00 น.</div>
                <br />
                <div><h2>🤝 แล้วพบกัน สู้ๆ! 🤳🏼</h2></div>
            </div>
            :``}
          </>
        )
    }
}
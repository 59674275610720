import React from "react";
import CandidateDetail from "./CandidateDetail.jsx";
import {Link} from "react-router-dom";

export default class CandidatePrint extends CandidateDetail {

    constructor(props) {
        super(props);

        this.pageCode = 'candidate-print';
        this.hub = props.hub;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // Dont use xhr request when call window.print(), issue on ios safari
        // this.hub.pushPath(this.pageCode);
        // this.hub.logStat(this.pageCode, 'candidate-print', {
        //     candidate: this.candidate
        // });
    }

    render() {
        setTimeout(() => {
            window.print();
        }, 1000);

        const ua = navigator.userAgent || navigator.vendor || window.opera;
        const rules = [
            'WebView',
            '(iPhone|iPod|iPad)(?!.*Safari\/)',
            'Android.*(wv|\.0\.0\.0)',
        ];
        const regex = new RegExp(`(${rules.join('|')})`, 'ig');
        let isInApp = Boolean(ua.match(regex));

        let warningMessage;
        if (isInApp) {
            warningMessage = (
                <div className="d-print-none">
                    <div className=" alert alert-warning">
                        <strong>!</strong> หากหน้าต่างการพิมพ์ไม่ปรากฎ กรุณาเปิดด้วยเบราว์เซอร์ เช่น Chrome Safari Firefox
                    </div>
                    <br />
                </div>
            )
        }

        const item = this.candidate;



        return (
            <div className="container">
                <Link to={`/candidates/${item.province}/${item.no}`} className="btn btn-back btn-light lh0 d-print-none">
                    <i className="fa fa-chevron-left fa-2"></i>
                </Link>

                <div className="text-center">
                    {warningMessage}
                    <h3 className="d-print-none">พิมพ์เบอร์ผู้สมัคร</h3>
                    <br/>
                    <div className="shadow-lg">
                        <img src={`https://firebasestorage.googleapis.com/v0/b/thai-vote-pao-62.appspot.com/o/images%2Fcard%2F${this.province}.${this.no}.card.png?alt=media`} alt="x" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        )
    }
}

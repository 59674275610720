import React from "react";

import Party from "../data/party.json";

import {Link} from "react-router-dom";

export default class VisualCandidate extends React.Component {

    constructor(props) {
        super(props);

        this.hub = props.hub;
    }
    

    render() {

        return (
            <div className="py-4 bg-dark-grey">
                <div className="container px-0 px-sm-3">
                    <h1 className="text-center text-white">ผู้สมัคร</h1>
                    <div className={`candidate-list d-flex flex-row justify-content-center align-items-start flex-wrap`}>
                        
                        {this.hub.candidates.map(candidate => 
                        <div key={candidate.order} style={{width: 180}} className={`p-2 ${candidate.party == 'ตัดสิทธิ์' ? 'disabled' : '' } `}>
                            <div className="candidate-pict position-relative">
                                <img className="img-fluid" src={`/static/images/candidate/candidate-${candidate.no}.png?k2`} srcSet={`/static/images/candidate/candidate-${candidate.no}.png?k2 1x, /static/images/candidate/candidate-${candidate.no}@2x.png?k2 2x`} />
                                <div className="rounded-circle text-center position-absolute d-flex align-items-center justify-content-center" style={{ backgroundColor: '#26282d' }}>
                                    <img className="img-fluid" src={`/static/images/icon-party/${candidate.party}.png?k2`} srcSet={`/static/images/icon-party/${candidate.party}.png?k2 1x, /static/images/icon-party/${candidate.party}@2x.png?k2 2x`} />
                                </div>
                            </div>
                            
                            <div className="candidate-desc d-flex flex-row align-items-start">
                                <div className="number rounded text-center text-white" style={{ backgroundColor: (Party[candidate.party.replace('พรรค', '')] && Party[candidate.party.replace('พรรค', '')].color) || '#888888' }}>{candidate.no}</div>
                                <div className="info text-white">
                                    <h5 className="mb-0 _fs-16">{`${candidate.name}`}</h5>
                                    <h3 className="mb-0 _fs-20 _fs-sm-24">{candidate.party}</h3>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>

        )
    }

}